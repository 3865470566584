import React, { useEffect, useState, ChangeEvent } from 'react';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash-es';

import { Button, ButtonType, ButtonIconPlacement } from '../../../../../components/Buttons/Button';
import { LabelStyle, TextInputType } from '../../../../../components/TextInput/TextInput';
import { InvoiceDTO, InvoiceDataResponseDTO, InvoiceCustomizationDTO, InvoiceFieldType, InvoiceCustomFieldItemDTO } from '../../../../../services/types/ApiTypes';
import { createDataId } from '../../../../../common/utils/dataId';
import { ICONS } from '../../../../../components/Icon/Icon';
import api from '../../../../../services/ApiServices';
import { getMaxItemsPerChunk, changeValueOfCustomizationChunkItem, sortCustomFields } from '../utils';
import { TypeaheadItem } from '../../../../../components/Typeahead/TypeaheadAsync';
import { notify } from '../../../../../common/utils/notify';
import useDataChunks from '../../../../../common/hooks/useDataChunks';
import { MAX_COLUMNS } from './CustomFieldsViewMode';
import { TypeaheadAsync } from '../../../../../components/Typeahead/TypeaheadAsync';
import CalendarDatePicker from '../../../../../components/CalendarDatePicker/CalendarDatePicker';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import classNames from 'classnames';

export const dataId = 'invoice-header.custom-fields-edit';

interface Props {
    customFields: InvoiceDTO['InvoiceCustomization'];
    invoice: InvoiceDataResponseDTO & InvoiceDTO;
    cancelEditting: () => void;
    t: TFunction;
    saveInvoice: (i: InvoiceDTO & InvoiceDataResponseDTO) => void;
    isInvoiceLoading: boolean;
}

const localDataId = 'custom-fields-edit';

const AdditionalInfoEditMode = ({ customFields, cancelEditting, invoice, t, saveInvoice: saveUpdatedInvoice, isInvoiceLoading }: Props) => {
    const [fieldsChunks, setFieldsChunks] = useDataChunks<InvoiceCustomizationDTO>(sortCustomFields(customFields), getMaxItemsPerChunk(customFields.length, MAX_COLUMNS));
    const [validationErrors, setValidationErrors] = useState<Map<string, string>>(new Map());
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

    const validateCustomFields = (): boolean => {
        const errors = new Map();

        fieldsChunks.forEach((cc) =>
            cc.forEach((c) => {
                // validation of mandatory fields
                if (c.Customization.IsMandatory && !c.Value) {
                    errors.set(c.Customization.Code, t('view.general.mandatoryField'));
                }
            }),
        );
        setValidationErrors(errors);
        return !!errors.size;
    };

    useEffect(() => {
        validateCustomFields();
    }, [fieldsChunks]);

    const getInvoiceCustomFieldItems = (field: string, customization: InvoiceCustomizationDTO) => {
        let customFieldItemPart;
        if (isEmpty(field) || field.split(' - ').length > 1) {
            // split to check if it is a compound value of 'Name - Code' and search by any
            customFieldItemPart = '';
        } else {
            customFieldItemPart = field;
        }

        const searchParams = {
            customizationId: customization.Customization.Id,
            page: 1,
            pagesize: 20,
            customFieldItemPart,
        };
        return api.invoice
            .getAvailableCustomizationFields(searchParams)
            .then((result) => result.data[0].Customization.InvoiceCustomFieldItems)
            .catch((error) => {
                notify.error(t('component.invoiceHeader.errorGettingCustomizationFields'));
                console.error(error);
            });
    };

    const mapFormValuesToInvoiceDTO = (): InvoiceDTO & InvoiceDataResponseDTO => {
        if (validateCustomFields()) {
            return undefined; // validation failed
        }
        const combineCustomizations = () => {
            const customizations: InvoiceCustomizationDTO[] = [];
            fieldsChunks.forEach((cc) => cc.forEach((c) => customizations.push(c)));
            return customizations;
        };
        const newInvoice = { ...invoice, InvoiceCustomization: combineCustomizations() };
        return newInvoice;
    };

    const saveInvoice = () => {
        const invoiceWithNewCusomizations = mapFormValuesToInvoiceDTO();
        invoiceWithNewCusomizations && saveUpdatedInvoice(invoiceWithNewCusomizations);
    };

    const changeCustomTypeaheadField = (field: InvoiceCustomFieldItemDTO) => {
        setFieldsChunks(changeValueOfCustomizationChunkItem(fieldsChunks, field.Code, field.InvoiceCustomFieldId));
        setIsFormDirty(true);
        validateCustomFields();
    };

    const changeCustomDateField = (day: string, customizationId: number) => {
        setFieldsChunks(changeValueOfCustomizationChunkItem(fieldsChunks, day, customizationId));
        setIsFormDirty(true);
        validateCustomFields();
    };

    const changeCustomTextField = (e: ChangeEvent<HTMLInputElement>, customizationId: number) => {
        setFieldsChunks(changeValueOfCustomizationChunkItem(fieldsChunks, e.currentTarget?.value, customizationId));
        setIsFormDirty(true);
        validateCustomFields();
    };

    const getCustomFieldInput = (field: InvoiceCustomizationDTO, wrapperClass: string) => {
        const fieldType = field?.Customization?.FieldType;
        switch (fieldType) {
            case InvoiceFieldType.Dropdown:
                const emptyField = { InvoiceCustomFieldId: field.CustomizationId, Code: null } as InvoiceCustomFieldItemDTO;
                return (
                    <TypeaheadAsync
                        dataId={createDataId(dataId, localDataId, field.Customization.Description)}
                        placeholder={field.Customization.Description}
                        searchOnFocus
                        toggleVisible
                        key={`custom-field-${field.Customization.Code}`}
                        inputProps={{ type: TextInputType.COMPACT, label: field.Customization.Description, labelStyle: LabelStyle.UPPERCASE }}
                        debounceInterval={400}
                        loadData={(s: string) => getInvoiceCustomFieldItems(s, field)}
                        value={{ value: field.Value, text: field.Value }}
                        wrapperClass={wrapperClass}
                        error={validationErrors.get(field.Customization.Code)}
                        onChange={(v: TypeaheadItem<InvoiceCustomFieldItemDTO>) => changeCustomTypeaheadField(v?.value || emptyField)}
                        itemToText={(f: InvoiceCustomFieldItemDTO) => `${f.Description} - ${f.Code}`}
                    />
                );

            case InvoiceFieldType.DateTime:
                const dateValue = field.Value ? new Date(field.Value) : null;
                return (
                    <CalendarDatePicker
                        key={`custom-field-${field.Customization.Code}`}
                        dataId={createDataId(dataId, localDataId, field.Customization.Description)}
                        label={field.Customization.Description}
                        value={dateValue}
                        error={validationErrors.get(field.Customization.Code)}
                        wrapperClass={wrapperClass}
                        onChange={(d: string) => changeCustomDateField(d, field.CustomizationId)}
                    />
                );
            default:
                return (
                    <TextInput
                        dataId={createDataId(dataId, localDataId, field.Customization.Description)}
                        key={`custom-field-${field.Customization.Code}`}
                        placeholder={field.Customization.Description}
                        labelStyle={LabelStyle.UPPERCASE}
                        type={TextInputType.COMPACT}
                        label={field.Customization.Description}
                        value={field.Value}
                        error={validationErrors.get(field.Customization.Code)}
                        wrapperClass={wrapperClass}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => changeCustomTextField(e, field.CustomizationId)}
                    />
                );
        }
    };

    const totalChunks = fieldsChunks.length;

    return (
        <>
            <div className="invoice-header__fields">
                {totalChunks &&
                    Array.from('l'.repeat(totalChunks)).map((l, i) => {
                        const wrapperClass = classNames({ 'limit-width': i !== totalChunks - 1 });
                        return <ul key={`${localDataId}-column-${i}`}>{fieldsChunks[i].map((f) => getCustomFieldInput(f, wrapperClass))}</ul>;
                    })}
            </div>
            <div className="invoice-header__actions invoice-header__actions--save-cancel">
                <div className="text-button-wrapper">
                    <Button
                        onClick={cancelEditting}
                        dataId={createDataId(dataId, 'cancelEdittingButton')}
                        buttonType={ButtonType.ICON_TEXT}
                        icon={ICONS.CLOSE_SMALL}
                        iconPlacement={ButtonIconPlacement.LEFT}
                    >
                        {t('component.invoiceHeader.cancelEditing')}
                    </Button>
                </div>
                <div className="text-button-wrapper">
                    <Button
                        disabled={!isFormDirty || isInvoiceLoading}
                        buttonType={ButtonType.ICON_TEXT}
                        dataId={createDataId(dataId, 'submitButton')}
                        icon={ICONS.SAVE}
                        type="submit"
                        onClick={saveInvoice}
                        iconPlacement={ButtonIconPlacement.LEFT}
                    >
                        {t('component.invoiceHeader.save')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AdditionalInfoEditMode;
