'use strict';

import { numericSortCodes } from '../../../src/common/utils/numericSort';
import {SearchType, SortDirection} from "../../../src/services/types/ApiTypes";
import api from '../../../src/services/ApiServices';
import { checkUserOnlyRoleExpenseCreator } from '../../../src/common/user/userPermissionUtil';
import { EXPENSE_TYPE } from '../../../src/common/constants/appConstants';


angular.module('dstreamApp.filters').filter('accountsFilter', function() {
    return (array, query) => {
        query = query || '';
        const searchParams = {
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: query,
                    Values: undefined,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            SortItems: [
                {
                    SortColumn: 'Code',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 25,
            },
        };

        api.user.getActiveAccountsForUser(searchParams, true).then((response) => {
            return [{
                Name: '',
                Value: response.data.Items.map((acc) => ({
                    Name: acc.Code + " - " + acc.Description,
                    Value: acc.Id,
                })).sort(numericSortCodes)
            }];
        });
    }
});

angular
    .module('dstreamApp.components')
    .component('dsInvoiceFilter', {
        templateUrl: 'app/components/invoiceFilter/ds-invoice-filter.html',
        controller: DsInvoiceFilterController
    });

DsInvoiceFilterController.$inject = ['$rootScope', '$timeout', 'webServices', 'invoiceRegisterService', '$filter', 'invoiceService', 'constants', '$location', 'companyDataService'];

function DsInvoiceFilterController($rootScope, $timeout, webServices, invoiceRegisterService, $filter, invoiceService, constants, $location, companyDataService) {
    var ctrl = this;
    ctrl.showFilterOverlay = false;
    ctrl.searchPlaceholder = 'component.invoiceFilter.startSearching';
    ctrl.visibility = {
        showFilterOverlay: false,
        statusFilterVisible: false,
        usersFilterVisible: false,
        dimensionFilterVisible: false,
        moreFilterVisible: false,
        templateFilterVisible: false,
        invoiceTypesFilterVisible: false
    }; // visibility of filter component elements
    ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage() || invoiceRegisterService.createDefaultFilterObject(); // get intial filter object from service
    ctrl.filterRanges = {
        statusRange: [],
        usersRange: [],
        invoiceTypesRange: [],
        moreRange: [],
        templateRange: [],
        accountRange: [],
        dimensionRange: [],
        objectRange: []
    }; // all possible filter values for filter autocompletes
    ctrl.availableFilterTemplates = [];

    ctrl.getInputPlaceholder = getInputPlaceholder;
    ctrl.isDimensionFilterDisabled = isDimensionFilterDisabled;
    ctrl.getDimensionFilterPlaceholder = getDimensionFilterPlaceholder;
    ctrl.handleCostObjectiveRemove = handleCostObjectiveRemove;
    ctrl.handleCostObjectivesRemoved = handleCostObjectivesRemoved;

    function handleCostObjectivesRemoved() {
        ctrl.filterObject.objectsFilter = [];
    }

    function handleCostObjectiveRemove(tag) {
        ctrl.filterObject.objectsFilter = ctrl.filterObject.objectsFilter.filter(function(dimension) {
            return dimension.costObjectiveId !== tag.Value;
        });
    }

    function getInputPlaceholder(placeholder) {
        let text;
        if (placeholder) {
            text = $filter('translate')(placeholder);
        } else {
            text = $filter('translate')('component.invoiceFilter.find');
        }
        text = text + '...';
        return text
    }

    function isDimensionFilterDisabled() {
        return ctrl.filterObject.dimensionFilter.length < 1;
    }

    function getDimensionFilterPlaceholder() {
        if (isDimensionFilterDisabled()) {
            return getInputPlaceholder('component.invoiceFilter.dimension.disabledText');
        }
        return getInputPlaceholder();
    }

    /*
        On component init
     */
    ctrl.$onInit = function() {
        ctrl.isUserOnlyExpenseCreator = checkUserOnlyRoleExpenseCreator();

        if (ctrl.isUserOnlyExpenseCreator) {
            // we need only current user
            ctrl.buildUsersRange();
        } else {
            // wait for user data before building the ranges
            companyDataService.getGroupMembers().then(function(res) {
                ctrl.buildUsersRange();
            });
        }
        api.invoiceTypes.getInvoiceTypes({PagingOptions: {Page: 1, Count: 1000}}).then(function(res) {
            if(res && res.data && res.data.Items) {
                let invoiceTypes = res.data.Items;
                if (ctrl.isUserOnlyExpenseCreator) {
                    // ExpeseCreator can only see Expenses
                    invoiceTypes = invoiceTypes.filter(i => i.Description === EXPENSE_TYPE);
                    ctrl.filterObject = {...ctrl.filterObject, invoiceTypesFilter: invoiceTypes, usersFilter: []};
                }
                ctrl.buildInvoiceTypesRange(invoiceTypes);
                if (ctrl.isUserOnlyExpenseCreator) {
                    ctrl.doSearch({...ctrl.filterObject, invoiceTypesFilter: ctrl.filterRanges.invoiceTypesRange[0].Value})
                }
            }
        }).catch(function(e) {
            console.error('Error loading invoice types', e);
        })
        ctrl.buildStatusRange();
        ctrl.buildMoreRange();
        ctrl.buildTemplateRanges();
        ctrl.buildDimensionAndObjectRange();
    };

    /*
        ============= RANGE BUILDING ===============
        Ranges are arrays of possible values for the tag inputs. They are used for tag input autocompletes
        Name have to be different, hence the '', ' '...
     */
    ctrl.buildStatusRange = function() {
        // add new & assigned options
        ctrl.filterRanges.statusRange.push({Name: '', Value: [0,2].map(function(statusCode) {
            return {
                Value: statusCode,
                TranslateKey: constants.getInvoiceStatusTranslateKey(statusCode),
                Name: $filter('translate')(constants.getInvoiceStatusTranslateKey(statusCode))
            }
        })});

        // add approved subcategory
        ctrl.filterRanges.statusRange.push({Name: constants.getInvoiceStatusTranslateKey('Approved'), GroupSelector: true, Splitter: false, Value: [7,3,4].map(function(statusCode) {
            return {
                Value: statusCode,
                TranslateKey: constants.getInvoiceStatusTranslateKey(statusCode),
                Name: $filter('translate')(constants.getInvoiceStatusTranslateKey(statusCode))
            }
        })});

        // add deleted + rejected option
        ctrl.filterRanges.statusRange.push({Name: ' ', Value: [5,9].map(function(statusCode) {
            return {
                Value: statusCode,
                TranslateKey: constants.getInvoiceStatusTranslateKey(statusCode),
                Name: $filter('translate')(constants.getInvoiceStatusTranslateKey(statusCode))
            }
        })});
    };

    ctrl.buildUsersRange = function() {
        ctrl.filterRanges.usersRange.push({Name: '', Value: [
            {
                Value: $rootScope.userData.BOGuid,
                TranslateKey: 'component.invoiceFilter.byMe',
                Name: $filter('translate')('component.invoiceFilter.byMe')
            }
        ]});

        ctrl.filterRanges.usersRange.push({
            Value: _.sortBy(getGroupMembers(), 'Name')
        });
    };

    ctrl.buildInvoiceTypesRange = function(items) {
        ctrl.filterRanges.invoiceTypesRange = [{
            Name: '',
            Value: items.map(function(i) {
                return ({
                    Name: i.Code + ' - ' + i.Description,
                    Value: i.Id
                })
            })
        }];
    }

    ctrl.buildMoreRange = function() {
        // if current company has any related companies associated with it, then enable the possibility to filter by them
        webServices.getAllCompaniesFromTheSameOrganizationByName('').then(function (response) {
            ctrl.filterRanges.moreRange = [{Name: '', Value: []}];
            if (response.data && response.data.length > 1) {
                ctrl.filterRanges.moreRange[0].Value.push(
                    {
                        Name: $filter('translate')("component.invoiceFilter.relatedCompanies"),
                        TranslateKey: 'component.invoiceFilter.relatedCompanies',
                        Value: 1
                    }
                );
            }
            ctrl.filterRanges.moreRange[0].Value.push(
                {
                    Name: $filter('translate')("component.invoiceFilter.OnlyCreditInvoice"),
                    TranslateKey: 'component.invoiceFilter.OnlyCreditInvoice',
                    Value: 2
                },
                {
                    Name: $filter('translate')("component.invoiceFilter.OnlyDebitInvoice"),
                    TranslateKey: 'component.invoiceFilter.OnlyDebitInvoice',
                    Value: 3
                },
                {
                    Name: $filter('translate')("component.invoiceFilter.InvoiceCustomFields"),
                    TranslateKey: 'component.invoiceFilter.InvoiceCustomFields',
                    Value: 4
                }
            );
        }, function (data) {
            console.log(data);
        });
    };

    ctrl.buildTemplateRanges = function() {
        ctrl.filterRanges.templateRange = []; // clear template ranges before building it incase we want to rebuild it
        invoiceRegisterService.getAvailableFilterTemplates().then(function (response) {
            if (response.data) {
                ctrl.availableFilterTemplates = response.data;
                ctrl.filterRanges.templateRange.push({Name: '', Value: response.data.map(function(template) {
                    return {
                        Name: template.Name,
                        Value: template.Id
                    };
                })});
            }
        }, function (data) {
            console.log(data);
        });
    };

    ctrl.loadAccounts = (query) => {
        query = query || '';

        const searchParams = {
            Restrictions: [
                {
                    Field: 'GeneralSearch',
                    Value: query,
                    Values: undefined,
                    FieldSearchType: SearchType.NotSelected,
                },
            ],
            SortItems: [
                {
                    SortColumn: 'Code',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 25,
            },
        };

        return webServices.getAccounts(searchParams).then((response) => {
            return ([{
                Name: '',
                visible: true,
                Value: response.data.Items.map((item) => ({
                    Name: item.Code + " - " + item.Description,
                    Value: item.Id,
                    isTag: !!ctrl.filterObject.accountsFilter.find((account) => account.Value === item.Id),
                    visible: true,
                })).sort(numericSortCodes),
            }]);
        });
    };

    ctrl.buildDimensionAndObjectRange = function() {
        webServices.getAllCompanyCustomCostObjectivesWithoutItems().then(function (response) {
            var dimensionRange = [];
            dimensionRange.push({Name: '', Value: []});

            for (var i = 0; i < response.data.Result.length; i++) {
                var template = response.data.Result[i];
                dimensionRange[0].Value.push({
                    Name: template.Code + " - " + template.Description,
                    Value: template.Id
                });
            }

            ctrl.filterRanges.dimensionRange = dimensionRange;

            ctrl.filterRanges.objectRange = (query) => {
                const searchParams = {
                    SortItems: [
                        {
                            SortColumn: "Id",
                            SortDirection: 1
                        }
                    ],
                    PagingOptions: {
                        Count: 25,
                        Page: 0
                    },
                    Restrictions: [
                        {
                            Field: "GeneralSearch",
                            Value: query,
                            FieldSearchType: 0
                        }
                    ],
                };

                if (ctrl.debounce) {
                    $timeout.cancel(ctrl.debounce);
                }

                return new Promise((resolve) => {
                    $timeout(() => {
                        const promises = [];
                        ctrl.filterObject.dimensionFilter.forEach((dimension) => {
                            promises.push(webServices.getDimensions(dimension.Value, searchParams, undefined));
                        });
                        Promise.all(promises).then((responses) => {
                            const items = responses.map((r) => r.data.Items).flat();
                            resolve([{
                                        Name: '',
                                        Value: items.map((i) => ({
                                            Name: i.Code + " - " + i.Description,
                                            Value: i.Id,
                                            costObjectiveId: i.CustomCostObjectiveId,
                                            visible: true,
                                        })),
                                        visible: true,
                                    }
                                ])
                        });
                    }, 200);
                });
            };
        });
    };

    /*
        ============= EVENT LISTENERS ===============
     */
    var invoiceRegisterFilterClearedEvent = $rootScope.$on('invoiceRegisterFilterCleared', function(event, defaultFilterObject) {
        ctrl.filterObject = defaultFilterObject;
    });
    var invoiceRegisterLoadedEvent = $rootScope.$on('invoiceRegisterLoaded', function(event, data) {
        // TODO: fix angular.copy for post-1.6.9
        ctrl.filterObject = angular.copy(data.filterObject); // get a copy so we wouldn't change the actual value in the service
    });
    var savedTemplateEvent = $rootScope.$on('savedTemplate', function() {
        ctrl.buildTemplateRanges();
    });
    var templateSavedEvent = $rootScope.$on('templateSaved', function() {
        ctrl.buildTemplateRanges();
    });

    /*
        ============= FILTER SHOW/HIDE ===============
     */

    /*
        Show defined filter
     */
    ctrl.showFilter = function($event, filterName) {
        ctrl.visibility.showFilterOverlay = true; //show backdrop
        // focus on input when the filter opens, but not for dimension filter, because it has different UI
        if(filterName !== 'dimension') {
            $timeout(function () {
                angular.element($event.currentTarget).parent().find('.tags').click();
            }, 100);
        }

        // show predefined filter
        switch(filterName) {
            case 'status':
                ctrl.visibility.statusFilterVisible = true;
                break;
            case 'users':
                ctrl.visibility.usersFilterVisible = true;
                break;
            case 'invoiceTypes':
                ctrl.visibility.invoiceTypesFilterVisible = true;
                break;
            case 'dimension':
                ctrl.visibility.dimensionFilterVisible = true;
                setTimeout(function() {
                    $(document).bind('click', ctrl.hideDimensionFilter);
                });
                break;
            case 'more':
                ctrl.visibility.moreFilterVisible = true;
                break;
            case 'templates':
                ctrl.visibility.templateFilterVisible = true;
                break;
        }
    };

    /*
        Hide all filters
     */
    ctrl.hideFilters = function($event) {
        ctrl.visibility.showFilterOverlay = false;
        ctrl.visibility.statusFilterVisible = false;
        ctrl.visibility.usersFilterVisible = false;
        ctrl.visibility.dimensionFilterVisible = false;
        ctrl.visibility.moreFilterVisible = false;
        ctrl.visibility.templateFilterVisible = false;
        ctrl.visibility.invoiceTypesFilterVisible = false;
        setTimeout(function () {
            $(document).unbind('click', ctrl.hideDimensionFilter);
        });
    };

    /*
        Hide dimension filter
     */
    ctrl.hideDimensionFilter = function($event) {
        if(ctrl.visibility.dimensionFilterVisible && angular.element($event.target).closest('.hz-invoice-filter__container--plain').length === 0 &&
            !angular.element($event.target).hasClass('filter-submit')) {
            ctrl.visibility.showFilterOverlay = false;
            ctrl.visibility.dimensionFilterVisible = false;
            setTimeout(function () {
                $(document).unbind('click', ctrl.hideDimensionFilter);
            });
        }
    };

    /*
        ============= FILTER ACTIONS =============
     */

    /*
        Apply all filters
     */
    ctrl.doSearch = function (filterObject) {
        const usableFilter = filterObject || ctrl.filterObject;
        
        //EMR-5215 case when filter had no pagingOptions saved
        if (usableFilter.pagingOptions) {
            usableFilter.pagingOptions.page = 1; // set page pack to beginning
        } else {
            usableFilter.pagingOptions = {
                page: 1,
                count: 10,
                total: 0
            }
        }

        const totalAmountsWillLoad = $rootScope.totalAmountsWillLoad;
        invoiceRegisterService.doSearch(usableFilter, false, true, totalAmountsWillLoad, false, false).then(
            function (result) {
                ctrl.filterObject = { ...result.filterObject };
                $rootScope.$emit('invoiceRegisterLoaded', {
                    result: result.result,
                    filterObject: result.filterObject,
                });
            }); // we use the invoiceRegisterLoaded eventwatcher in this component
    };

    /*
        Clear all filters
     */
    ctrl.clearFilters = function() {
        invoiceRegisterService.clearFilters(false, $rootScope.totalAmountsWillLoad).then(function(result) {
            $rootScope.$emit('invoiceRegisterLoaded',
                {
                    result: result.result,
                    filterObject: result.filterObject
                });
        });
    };

    /*
        Apply filter template. First check if we have a selected template from the dropdown.
     */
    ctrl.applyFilterTemplate = function() {
        var selectedFilter = _.find(ctrl.filterRanges.templateRange[0].Value, function (f) { return f.isTag });
        if(selectedFilter) {
            ctrl.doSearch(JSON.parse(_.find(ctrl.availableFilterTemplates, function(f) { return f.Id === selectedFilter.Value}).JSON));
        }
    };

    /*
        ============= UTILITIES ================
     */

    /*
        Get groupmembers for the confirmers filter
     */
    function getGroupMembers(namePart) {
        var temp = [];
        namePart = namePart ? namePart : '';
        if($rootScope.groupMembers) {
            $rootScope.groupMembers.forEach(function (value) {
                var userName = invoiceService.getUserFullNameById(value.User.GroupMember.Id, $rootScope.companyData.Users);
                if (userName.toLowerCase().indexOf(namePart.toLowerCase()) > -1 && value.Id !== $rootScope.userData.GroupMemberId) {
                    if(!_.find(temp, function(tempObj) { return tempObj.Value === value.Id || tempObj.Name === userName})) {
                        temp.push({
                            Value: value.UserGuid,
                            Name: userName
                        });
                    }
                }
            });
        }
        return temp;
    }

    /*
        Add up accounts, dimensions and objects
     */
    ctrl.dimensionsFiltersCount = function() {
        return ctrl.filterObject.dimensionFilter.length + ctrl.filterObject.accountsFilter.length + ctrl.filterObject.objectsFilter.length;
    };

    /*
        ============= ANGULAR FUNCTIONS ================
     */

    /*
        On componentF destroy
     */
    ctrl.$onDestroy = function() {
        invoiceRegisterFilterClearedEvent();
        savedTemplateEvent();
        templateSavedEvent();
        invoiceRegisterLoadedEvent();
    };
}
