import { cloneDeep, find, isEqual } from 'lodash-es';
import { selectRoutesHistory } from '../../../src/common/history/InvoiceFilterReducer';
import { getViewUserSearchParams } from "../../../src/common/user/userSettingUtil";
import { SortDirection, UserSettingName } from "../../../src/services/types/ApiTypes";
import { encodeUriFilterObject } from "../../../src/views/invoice-details/components/invoice-slider/InvoiceSliderHelpers";
import { getCurrentUserGroupMember } from "../../../src/common/user/UserActions";
import { updateAppUserSettingsAction } from "../../../src/common/middlewares/userSettings";
import { checkUserOnlyRoleExpenseCreator } from '../../../src/common/user/userPermissionUtil';
import { formatMoneyToShowSeparators } from '../../../src/views/invoice-details/components/invoice-header/utils';
import {formatNetVatTotal} from '../../../src/components/MonetaryPrecisionScales/hooks/useMonetaryScalesFormat';
import { selectCurrentCompanySettings } from '../../../src/common/company/CompanySelectors';


"use strict";

angular
    .module("dstreamApp.components")
    .component("dsInvoiceRegisterTable", {
        templateUrl: "app/components/invoiceRegisterTable/ds-invoice-register-table.html",
        controller: DsInvoiceRegisterTableController
    });

function DsInvoiceRegisterTableController(
    $rootScope,
    $scope,
    $routeParams,
    webServices,
    authenticationService,
    utils,
    $location,
    $filter,
    constants,
    invoiceRegisterService,
    localStorageService,
    $ngRedux,
    $q,
) {
    var ctrl = this;
    ctrl.listViewConfig = {
        sortDir: UserSettingName.PURCHASE_INVOICES_SORT_DIRECTION,
        sortCol: UserSettingName.PURCHASE_INVOICES_SORT_COLUMN,
        pageSize: UserSettingName.PURCHASE_INVOICES_PAGE_SIZE,
    };
    ctrl.searchParams = {
        PagingOptions: {
            Page: 0,
            Count: 0,
        },
        SortItems: [
            {
                SortDirection: 0,
                SortColumn: '',
            },
        ],
        Restrictions: [],
    };
    ctrl.invoiceArray = [];
    ctrl.invoiceRegisterLoading = false;
    ctrl.filterObject = invoiceRegisterService.getFilterFromLocalStorage() || invoiceRegisterService.createDefaultFilterObject(); // get intial filter object from service
    ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1));
    ctrl.getInvoiceStatusTranslateKey = constants.getInvoiceStatusTranslateKey;
    ctrl.pageSizes = constants.DEFAULT_PAGE_SIZES;
    ctrl.availableFilterTemplates = [];
    ctrl.totalAmounts = [];
    ctrl.isERPIdEnabled = false;
    ctrl.isNewSupplierVisible = false;
    ctrl.isExportPopupOpen = false;
    $rootScope.isPDFVisible = false; // TODO refactor this
    ctrl.formatMoneyToShowSeparators = formatMoneyToShowSeparators;
    ctrl.saveFilterTemplatePopover = {
        open: false,
        filterName: "",
        isDefault: false,
        template: "invoice-register-save-filter-template.html",
        openPopover: function (e) {
            e.preventDefault();
            ctrl.saveFilterTemplatePopover.open = true;
            ctrl.saveFilterTemplatePopover.filterName = "";
            ctrl.saveFilterTemplatePopover.isDefault = false;
        },
        cancel: function () {
            ctrl.saveFilterTemplatePopover.open = false;
        },
        save: function () {
            ctrl.saveFilterTemplatePopover.open = false;
            invoiceRegisterService.saveFilterTemplate(ctrl.availableFilterTemplates, ctrl.saveFilterTemplatePopover.filterName, ctrl.saveFilterTemplatePopover.isDefault);
        }
    };

    ctrl.formatNetVatTotal = (value) =>{
        const settingsList = selectCurrentCompanySettings($ngRedux.getState());
        const setting = settingsList.find((setting) => setting.Name === 'MonetaryNetVatTotalPrecision');
        const decimalScale = Number(setting.Value || 2)
        return formatNetVatTotal(value, { decimalScale, thousandSeparator: ' ' });
    };

    /*
          ============= EVENT LISTENERS ===============
       */
    var invoiceRegisterLoadingEvent = $rootScope.$on("invoiceRegisterLoading", function () {
        ctrl.invoiceRegisterLoading = true;
    });
    var invoiceRegisterLoadedEvent = $rootScope.$on("invoiceRegisterLoaded", function (event, data) {
        ctrl.invoiceArray = data.result;
        ctrl.invoiceRegisterLoading = false;
        ctrl.filterObject = { ...data.filterObject };
        ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1));
    });
    var savingTemplateEvent = $rootScope.$on("savingTemplate", function () {
        ctrl.invoiceRegisterLoading = true;
    });
    var invoiceRegisterFilterClearedEvent = $rootScope.$on("invoiceRegisterFilterCleared", function (event, defaultFilterObject) {
        ctrl.filterObject = defaultFilterObject;
        ctrl.getUriFilterObject = encodeUriFilterObject(invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1));
    });
    var savedTemplateEvent = $rootScope.$on("templateSaved", function () {
        ctrl.invoiceRegisterLoading = false;
        ctrl.saveFilterTemplatePopover.filterName = "";
        ctrl.saveFilterTemplatePopover.isDefault = false;
        getFilterTemplates();
    });
    var invoiceRegisterTotalAmountsEvent = $rootScope.$on("invoiceRegisterTotalAmounts", function (event, data) {
        ctrl.totalAmounts = data;
    });

    ctrl.getFilterDateRangeLabel = getFilterDateRangeLabel;
    ctrl.exportInvoicesToCSV = exportInvoicesToCSV;
    ctrl.exportInvoicesToXls = exportInvoicesToXls;
    ctrl.toggleDateRangeFilter = toggleDateRangeFilter;
    ctrl.updateSelectedFilterTemplate = updateSelectedFilterTemplate;
    ctrl.shiftDateRange = shiftDateRange;
    ctrl.getFilterTemplatesByNamepart = getFilterTemplatesByNamepart;
    ctrl.openInvoice = openInvoice;
    ctrl.doSearch = doSearch;
    ctrl.changeSorting = changeSorting;
    ctrl.changePageSize = changePageSize;
    ctrl.pageChanged = pageChanged;
    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;
    ctrl.totalAmountsWillLoad = $rootScope.totalAmountsWillLoad; // is button "Calculate Total" shown
    ctrl.calculateTotalsClick = calculateTotalsClick;
    ctrl.toggleExportPopup = toggleExportPopup;

    /*
          =============== TABLE HEADER ACTIONS ================
       */

    // reset filters and calculate total
    function calculateTotalsClick() {
        $rootScope.setTotalAmountsWillLoad(true);
        ctrl.totalAmountsWillLoad = $rootScope.totalAmountsWillLoad;
        doSearch(ctrl.filterObject, true, false);
    }

    function getFilterDateRangeLabel(filterObject) {
        let label, labelFrom, labelTo;
        switch (filterObject.timeType) {
            case 'customDates':
            case 'shiftedCustomMonth':
            case 'shiftedCustomYear':
                if (filterObject.fromModel !== 'fromBeginningOfTime') {
                    labelFrom = $filter('date')(filterObject.fromModel, 'dd.MM.yyyy')
                } else {
                    labelFrom = $filter('translate')('component.invoiceFilter.from')
                }
                if (filterObject.toModel !== 'untilEndOfTime') {
                    labelTo = $filter('date')(filterObject.toModel, 'dd.MM.yyyy');
                } else {
                    labelTo = $filter('translate')('component.invoiceFilter.to');
                }
                label = labelFrom + ' - ' + labelTo;
                break;
            case 'lastSixMonths':
                label = $filter('translate')(filterObject.timeTypeLabel, {
                    day: filterObject.lastSixMonths
                });
                break;
            default:
                label = $filter('translate')(filterObject.timeTypeLabel, {
                    day: filterObject.lastXDaysModel
                });
                break;
        }
        return label;
    }

    function toggleExportPopup(event, isOpen) {
        if (event !== undefined) {
            event.stopPropagation();
        }
        if (isOpen !== undefined) {
            ctrl.isExportPopupOpen = isOpen;
        } else {
            ctrl.isExportPopupOpen = !ctrl.isExportPopupOpen;
        }
    }

    /*
          Export currently filtered invoices
       */
    function exportInvoicesToCSV(e) {
        e.preventDefault();
        invoiceRegisterService.exportInvoicesToCSV(ctrl.filterObject);
        ctrl.isExportPopupOpen = false;
    }

    function exportInvoicesToXls(e) {
        e.preventDefault();
        invoiceRegisterService.exportInvoicesToXls(ctrl.filterObject);
        ctrl.isExportPopupOpen = false;
    }

    /*
          Toggle date range filter visibility
       */
    function toggleDateRangeFilter(e) {
        e.preventDefault();
        $rootScope.$emit("toggleDateRangeVisibility");
    }

    /*
          Update filter template form with preset selected template values
       */
    function updateSelectedFilterTemplate(filter) {
        ctrl.saveFilterTemplatePopover.filterName = filter.Name;
        ctrl.saveFilterTemplatePopover.isDefault = filter.IsDefault;
    }

    /*
          Shift date rage filter back by how many days are in the range
      */
    function shiftDateRange(direction) {
        var originalFromModel = cloneDeep(ctrl.filterObject.fromModel);
        var originalToModel = cloneDeep(ctrl.filterObject.toModel);
        if (ctrl.filterObject.timeType === "currentMonth" || ctrl.filterObject.timeType === "lastMonth" || ctrl.filterObject.timeType === "shiftedCustomMonth") {
            // if we are shifting by months
            ctrl.filterObject.fromModel = direction === "forward" ? utils.getFirstDateOfNextMonth(originalToModel) : utils.getFirstDateOfPreviousMonth(originalToModel);
            ctrl.filterObject.toModel = direction === "forward" ? utils.getLastDateOfNextMonth(originalToModel) : utils.getLastDateOfPreviousMonth(originalToModel);
            ctrl.filterObject.timeType = "shiftedCustomMonth";
        } else if (ctrl.filterObject.timeType === "thisYear" || ctrl.filterObject.timeType === "shiftedCustomYear") {
            // if we are shifting by years
            ctrl.filterObject.fromModel = direction === "forward" ? utils.getFirstDateOfNextYear(originalToModel) : utils.getFirstDateOfPreviousYear(originalToModel);
            ctrl.filterObject.toModel = direction === "forward" ? utils.getLastDateOfNextYear(originalToModel) : utils.getLastDateOfPreviousYear(originalToModel);
            ctrl.filterObject.timeType = "shiftedCustomYear";
        } else {
            // if we are not shifting by months or years
            ctrl.filterObject.fromModel = direction === "forward" ? utils.getNextDay(originalToModel) : utils.shiftBack(originalFromModel, originalToModel);
            ctrl.filterObject.toModel = direction === "forward" ? utils.shiftForward(originalFromModel, originalToModel) : utils.getPreviousDay(originalFromModel);
            ctrl.filterObject.timeType = "customDates";
        }

        ctrl.filterObject.pagingOptions.page = 1; // set page pack to beginning
        doSearch(ctrl.filterObject, false, true);
    }

    function getFilterTemplatesByNamepart(namepart) {
        return _.filter(ctrl.availableFilterTemplates, function (f) { return f.Name.toLowerCase().indexOf(namepart.toLowerCase()) > -1; });
    }

    /*
          ============= TABLE ACTIIONS ================
       */
    /*
          Open invoice details and if the invoice is under a related company then do company switch first
       */
    function openInvoice(invoice, e) {
        e.preventDefault();
        invoiceRegisterService.saveFilterToLocalStorage(ctrl.filterObject, 0, 1);
        if (authenticationService.isAuthorized("CanAddInvoice") && invoice.CompanyGuid !== $rootScope.companyData.CompanyGuid && invoice.Status !== 6) {
            // its related Company invoice
            authenticationService.changeCompany(invoice.CompanyGuid).then(function () {
                $location.path("/invoiceconfirmation/" + invoice.Id);
            });
        } else {
            // its the same company invoice
            $location.path("/invoiceconfirmation/" + invoice.Id);
        }
    }

    /*
          Do actual search
       */
    function doSearch(filterObject, skipFilterSave, skipApplyDefaultFilter) {
        invoiceRegisterService.doSearchWithQueue(filterObject, skipFilterSave, skipApplyDefaultFilter, ctrl.totalAmountsWillLoad, false).then(function (result) {
            if (result && result.result && result.filterObject) {
                $rootScope.$emit("invoiceRegisterLoaded", {
                    result: result.result,
                    filterObject: result.filterObject
                });
            }
        }); // we use the invoiceRegisterLoaded eventwatcher in this component
    }

    function changeSorting(sortColumn, sortDirection) {
        $ngRedux.dispatch(updateAppUserSettingsAction({
            listViewConfig: ctrl.listViewConfig,
            searchParams: {
                ...ctrl.searchParams,
                SortItems: [{
                    SortDirection: sortDirection === SortDirection.Desc ? SortDirection.Desc : SortDirection.Asc,
                    SortColumn: sortColumn,
                }]
            },
        })).then((response) => {
            ctrl.searchParams = {
                ...response.payload.searchParams
            };
            doSearch(ctrl.filterObject, false, true);
        });
    }

    /*
          Change the max row count in the table and also set pagination to the first page and fill table with new data
       */
    function changePageSize(size) {
        ctrl.filterObject.pagingOptions.count = size;
        ctrl.filterObject.pagingOptions.page = 1;

        $ngRedux.dispatch(updateAppUserSettingsAction({
            listViewConfig: ctrl.listViewConfig,
            searchParams: {
                ...ctrl.searchParams,
                PagingOptions: {
                    ...ctrl.searchParams.PagingOptions,
                    Count: size,
                }
            },
        })).then((response) => {
            ctrl.searchParams = {
                ...response.payload.searchParams
            };
            doSearch(ctrl.filterObject, false, true);
        });
    }

    /*
          Pagination page change
       */
    function pageChanged(value) {
        ctrl.filterObject.pagingOptions.page = value;
        doSearch(ctrl.filterObject, false, true);
    }

    /*
          ============= UTILITIES ================
       */
    function getFilterTemplates() {
        return invoiceRegisterService.getAvailableFilterTemplates().then(function (response) {
            if (response.data) {
                ctrl.availableFilterTemplates = response.data;
            }
            return response;
        }, function (data) {
            console.log(data);
        });
    }

    function doSearchWithFilterParamsFromGroupMember(groupMember, filterObject) {
        // replace initial searchParams with values from userSettings in groupMember API response
        ctrl.searchParams = getViewUserSearchParams(ctrl.searchParams, ctrl.listViewConfig, groupMember);
        const referrer = $routeParams.from;

        if (!filterObject.pagingOptions || !isEqual(filterObject.pagingOptions.count, ctrl.searchParams.PagingOptions.Count)) {
            filterObject.pagingOptions = filterObject.pagingOptions ? { ...filterObject.pagingOptions, count: ctrl.searchParams.PagingOptions.Count } : { count: ctrl.searchParams.PagingOptions.Count };
        }
        if (!isEqual(filterObject.sortItems, ctrl.searchParams.SortItems)) {
            filterObject.sortItems = [
                ...ctrl.searchParams.SortItems,
            ];
        }
        doSearch(filterObject, true, !!referrer);
    }

    /*
      This is needed to wait for GroupMember while being loaded by another component (TopMenu), not to fetch it again
    */
    function getGroupMemberAndLoadData(filterObject) {
        let groupMember = $ngRedux.getState().user.groupMemberCommonLoadable.payload;

        let i = 0; // waiting iterations counter
        function waitForGroupMemberToLoad() {
            ctrl.invoiceRegisterLoading = true;
            setTimeout(() => {
                i++;
                const groupMember = $ngRedux.getState().user.groupMemberCommonLoadable.payload;
                if (!groupMember) {
                    if (i > 2) {
                        // if GM is not there after ~ 0.5sec waiting, relaunch the call (ideally this should not happen)
                        $ngRedux.dispatch(getCurrentUserGroupMember());
                        i = 0;
                    }
                    waitForGroupMemberToLoad();
                } else {
                    doSearchWithFilterParamsFromGroupMember(groupMember, filterObject);
                }
            }, 250)
        }

        if (!groupMember) {
            waitForGroupMemberToLoad();
        } else {
            doSearchWithFilterParamsFromGroupMember(groupMember, filterObject);
        }
    }

    function windowClick() {
        toggleExportPopup(undefined, false);
    }

    /*
          ============= ANGULAR FUNCTIONS ================
       */

    /*
          On component init
       */
    function init() {
        $q.all([
            invoiceRegisterService.getExportSetting(),
            getFilterTemplates(),
        ]).then((responseArr) => {
            const erpData = responseArr[0];
            const filterTemplates = responseArr[1];

            // get settings for special info in the invoice register table
            ctrl.isERPIdEnabled = erpData.isERPIdEnabled;
            ctrl.isNewSupplierVisible = erpData.isNewSupplierVisible;

            // // wait for the available template response and then do initial search
            const referrer = $routeParams.from;
            let localStorageKey;
            let filterObject;
            const locationHistory = selectRoutesHistory($ngRedux.getState());
            if (!!referrer) {
                localStorageKey = referrer + '.filterObject';
                filterObject = localStorageService.get(localStorageKey);
            } else if (locationHistory && locationHistory.previous && locationHistory.previous.filter && locationHistory.previous.filter.invoiceType === 0) {
                filterObject = { ...locationHistory.previous.filter };
            } else {
                const defaultFilterTemplate = find(filterTemplates.data, (filterTemplate) => filterTemplate.IsDefault);
                if (defaultFilterTemplate) {
                    filterObject = JSON.parse(defaultFilterTemplate.JSON);
                } else {
                    filterObject = invoiceRegisterService.createDefaultFilterObject();
                }
            }
            if (!checkUserOnlyRoleExpenseCreator()){
                // Don't load data for ExpenseCreator user, we need to apply the invoiceType filter first
                getGroupMemberAndLoadData(filterObject);
            }

        });

        window.document.addEventListener('click', windowClick);
    }

    /*
          On component destroy
       */
    function destroy() {
        invoiceRegisterLoadingEvent();
        invoiceRegisterLoadedEvent();
        invoiceRegisterFilterClearedEvent();
        savedTemplateEvent();
        savingTemplateEvent();
        invoiceRegisterTotalAmountsEvent();
        window.document.removeEventListener('click', windowClick);
    }
}