import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../rootReducer';

import { setPagingOptions } from '../../views/settings/suppliers/SuppliersViewActions';
import { DispatchProps, EditSupplierModal, Props } from './EditSupplierModal';
import { addOrUpdateSupplier, showEditSupplierModal } from './EditSupplierModalActions';
import { selectActiveSupplier, selectIsEditSupplierModalOpen, selectCurrentCompanyCountry } from './EditSupplierModalReducer';
import { selectSuppliersSearchParams } from '../../views/settings/suppliers/SuppliersViewReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        activeSupplier: selectActiveSupplier(state),
        isModalOpen: selectIsEditSupplierModalOpen(state),
        currentCompanyCountry: selectCurrentCompanyCountry(state),
        suppliersSearchParams: selectSuppliersSearchParams(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    saveSupplier: addOrUpdateSupplier,
    setModalOpen: showEditSupplierModal,
    updateSuppliersList: setPagingOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditSupplierModal));
