import {cloneDeep, uniqueId, debounce} from 'lodash-es';
import {SearchType, SortDirection} from "../../../../../src/services/types/ApiTypes";
import {numericSortCodes} from "../../../../../src/common/utils/numericSort";
import { formatDate } from '../../../../../src/common/utils/formatters';

(function () {
  "use strict";
  angular.module("dstreamApp.views.detailed-transactions")
    .factory("detailedTransactionsTableService", detailedTransactionsTableService);
  detailedTransactionsTableService.$inject = [
    "$rootScope",
    "webServices",
    "companyDataService",
    "activeInvoiceService",
    "notifyService",
    "$filter",
    "$timeout"
  ];

  function detailedTransactionsTableService (
    $rootScope,
    webServices,
    companyDataService,
    activeInvoiceService,
    notifyService,
    $filter,
    $timeout
  ) {
    var service = this;
    service.VatCodes = [];
    service.AccountList = [];
    service.mappedTableData = [];
    service.customCostObjectives = [];
    service.accountingDate = undefined;
    service.calls = {
      GetNewColumnObject: GetNewColumnObject,
      GetNewColumnDefinitionObject: GetNewColumnDefinitionObject,
      addColumnBasedOnCustomFields: addColumnBasedOnCustomFields,
      buildPossibleNewColumnsArray: buildPossibleNewColumnsArray,
      copyToAllTransactionRows: copyToAllTransactionRows,
      filterVatCodes: filterVatCodes,
      filterAccounts: filterAccounts,
      filterCustomFields: filterCustomFields,
      getAvailableAccounts: getAvailableAccounts,
      getAvailableVatCodes: getAvailableVatCodes,
      getCustomCostObjectives: getCustomCostObjectives,
      getCustomDimensions: getCustomDimensions,
      addMissingDimensionsToRows: addMissingDimensionsToRows,
      getDimensionNameById: getDimensionNameById,
      parseAccountantRows: parseAccountantRows,
      saveAccount: saveAccount,
      saveComment: saveComment,
      saveCustomField: saveCustomField,
      saveDate: saveDate,
      saveRow: saveRow,
      saveVatCode: saveVatCode,
      sortColumnDefs: sortColumnDefs,
      unparseAccountantRows: unparseAccountantRows,
      init: init,
      debounce: undefined,
    };
    return service.calls;

    function init (accountingDate) {
      service.accountingDate = accountingDate;
      return companyDataService.getVatCodes(true, accountingDate).then(
        function (response) {
          service.VatCodes = cloneDeep(response);
          return service.VatCodes;
        }
      ).then(
        companyDataService.getAccounts(true, accountingDate).then(
          function (response) {
            service.AccountList = cloneDeep(response);
            return service.AccountList;
          }
        )
      ).then(
          companyDataService.getCustomCostObjectives(true, true, undefined, accountingDate).then(
              function (response) {
                  service.customCostObjectives = response;
              }
          )
      );
    }

    function GetNewColumnObject (customField) {
        // return new Empty Column
        return {
            Description: customField ? customField.Description : "",
            Value: customField ? customField.Code : "",
            Id: customField.Id,
        };
    }

      function GetNewColumnDefinitionObject (column) {
          function applyCommentValues (obj) {
              obj.priority = 999;
              obj.cellFilter = "";
              obj.headerCellTemplate = "ui-grid/uiGridHeaderCellContentCopyable/text";
              obj.cellTemplate = "ui-grid/uiGridCell/text";
              obj.editableCellTemplate = "ui-grid/cellEditor/text";
              return obj;
          }

          var newColDef = {
              field: column ? column.Value : "",
              name: column ? column.Value : "" + uniqueId(),
              priority: 100,
              displayName: column ? column.Description : "",
              customCostObjectiveId: column ? column.Id : "",
              minWidth: 125,
              cellFilter: "",
              headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/typeahead",
              cellTemplate: "ui-grid/uiGridCell/dimension-typeahead",
              editableCellTemplate: "ui-grid/cellEditor/dimension-typeahead",
              refParameter: "Id",
              refAsParameter: "Description",
              typeaheadOptions: getCustomDimensions,
              filterFunction: filterCustomFields
          };

          if (column) {
              switch (column.Value) {
                  case "Comment":
                      newColDef = applyCommentValues(newColDef);
                      break;
                  default:
                      break;
              }
          }

          return newColDef;
      }

    function getCustomCostObjectives () {
        return service.customCostObjectives;
    }

    /*
        Get all avalable custom dimensions and also accounting date and comment and create an array
     */
    function buildPossibleNewColumnsArray (columnDefs) {
      var newColumnsArray = [];
      // add comment to list if they are not already in the table
      if (!_.find(columnDefs, function (def) { return def.field === "Comment"; })) {
        newColumnsArray.push({Description: $filter("translate")("component.transactionRows.comment"), Value: "Comment"});
      }
      // add all custom dimensions to the possibleColumnsArray that are not already in the table
      _.forEach(service.customCostObjectives, function (dimension) {
        if (!_.find(columnDefs, function (def) { return def.field === dimension.Code; })) {
          var newColumn = new GetNewColumnObject(dimension);
          newColumnsArray.push(newColumn);
        }
      });
      return newColumnsArray;
    }

    /*
        TODO this is also used in invoiceConfirmationController in function findCustomFieldItems. refactor this to one place
     */
    function getDimensionNameById (id, dimensionCategory, colDef) {
      if (id === undefined)
        return null;
      if (dimensionCategory && dimensionCategory.Dimension) {
        return dimensionCategory.Dimension.Code + " - " + dimensionCategory.Dimension.Description;
      }
      return null;
    }

    function filterVatCodes (input) {
      return function (item) {
        var searchableValue = item.Code + " - " + item.Description;
        var searchString = input || "";
        return searchableValue.toLowerCase().includes(searchString.toLowerCase());
      };
    }

    function filterAccounts (input) {
      return function (item) {
        var searchableValue = item.Code + " - " + item.Description;
        var searchString = input || "";
        return searchableValue.toLowerCase().includes(searchString.toLowerCase());
      };
    }

    function filterCustomFields (input) {
      return function (item) {
        var searchableValue = item.Code + " - " + item.Description;
        var searchString = input || "";
        return searchableValue.toLowerCase().includes(searchString.toLowerCase());
      };
    }

    /*
        Takes in the customCostObjectiveId and uses it to find the available Dimensions for that custom dimension
     */
    function getCustomDimensions (searchString, customCostObjectiveId, customCostObjectiveWithItem) {
      if (customCostObjectiveId) {
          var customCostObjective = _.find(service.customCostObjectives, function (t) {
              return t.Id === customCostObjectiveId;
          });
          if (customCostObjective) {

              if (customCostObjectiveWithItem) {
                  searchString = searchString === findCustomFieldItem(customCostObjectiveWithItem.Dimension) ? '' : searchString
              }

              return getDimensions(customCostObjective, searchString);
          }
      }
      return new Promise((resolve) => {resolve([])});
    }

      function findCustomFieldItem(dimension) {
          if (!dimension) {
              return null;
          }
          return dimension.Code + " - " + dimension.Description;
      }

      function getDimensions(customField, name) {
          var namePart = name || "";
          var dimensions = [];
          var itemWithFullName = {};
          var searchParams = {
              SortItems: [
                  {
                      SortColumn: "Code",
                      SortDirection: 0
                  }
              ],
              PagingOptions: {
                  Count: 25,
                  Page: 0
              },
              Restrictions: [
                  {
                      Field: "GeneralSearch",
                      Value: namePart,
                      FieldSearchType: 0
                  }
              ],
          };
          if (service.debounce) {
              $timeout.cancel(service.debounce);
          }
          service.debounce = $timeout(function () {
              dimensions = webServices.getDimensions(customField.Id, searchParams, service.accountingDate).then(
                  function (response) {
                      itemWithFullName = response.data.Items.map(function (item) {
                          item.FullName = findCustomFieldItem(item);
                          return item;
                      });
                      return itemWithFullName;
                  }
              );
              return dimensions;
          }, 200);
          return service.debounce;
      }


    /*
        Get all available vat codes
     */
    function getAvailableVatCodes (searchString) {
      if (service.VatCodes) {
        if (_.find(service.VatCodes, function (item) {
          return item.Code + " - " + item.Description === searchString;
        })) {
          return service.VatCodes;
        } else {
          return _.filter(service.VatCodes, function (item) {
            var regex = new RegExp((searchString || "").toLowerCase());
            return regex.test((item.Code + " - " + item.Description).toLowerCase());
          });
        }
      }
      return [];
    }

      function loadAccounts (query) {
          query = query || '';
          const searchParams = {
              Restrictions: [
                  {
                      Field: 'GeneralSearch',
                      Value: query,
                      Values: undefined,
                      FieldSearchType: SearchType.NotSelected,
                  },
              ],
              SortItems: [
                  {
                      SortColumn: 'Code',
                      SortDirection: SortDirection.Asc,
                  },
              ],
              PagingOptions: {
                  Page: 1,
                  Count: 25,
              },
          };
          return activeInvoiceService.activeInvoice().then((invoice) => {
              const {AccountingDate} = invoice;
              return webServices.getAccounts(searchParams, AccountingDate).then((response) => {
                  return response.data.Items.sort(numericSortCodes);
              });
          });
      }


    /*
        Get all available accounts
     */
    function getAvailableAccounts (searchString, dataCheck) {
        if (dataCheck) {
            return service.AccountList.length > 0;
        } else {
            return loadAccounts(searchString);
        }
    }

      /*
        Takes in invoice accountant rows and parses them into flat data that is suitable for the table
     */
    function parseAccountantRows (data, columnDefs) {
      service.mappedTableData = _.map(data, function (row) {
        // Sum/VatSum/Description etc are using objects instead of direct values because we need it to be an object to set the loading parameter on it
        var map = {
          Id: row.Id,
          Description: {value: row.Description},
          SumWithoutVat: {value: row.SumWithoutVat},
          VAT: {value: row.VAT},
          Total: {value: row.Total},
          OrderNo: row.OrderNo,
          VatRate: row.VatRate,
          VatCode: row.VatCode,
          VatCodeId: row.VatCodeId,
          Account: row.Account,
          AccountingDate: {value: row.AccountingDate ? new Date(row.AccountingDate) : null},
          Comment: {value: row.Comment}
        };
        // add custom dimensions columns to tabel and data to array
        if (row.TransactionRowsDimensions.length) {
          // try to add new columns to table based on rows custom fields. If column already excists,
          // then it's not added
          addColumnBasedOnCustomFields(row.TransactionRowsDimensions, columnDefs);
          // add data to table for each custom field
          _.forEach(row.TransactionRowsDimensions, function (customField) {
            const customCostObjectiveWithItems = service.customCostObjectives.find((t) => t.Id === customField.CustomCostObjectiveId);
            map[customField.CustomCostObjective.Id.toString()] = {
              original: customField,
              Id: customField.Id,
              CustomCostObjectiveId: customField.CustomCostObjectiveId,
              DimensionId: customField.DimensionId,
              Dimension: customField.Dimension,
              CustomCostObjective: {...customCostObjectiveWithItems}
            };
          });
        }
        // add comment column to table
        if (map.Comment.value && !_.find(columnDefs, function (def) { return def.field === "Comment"; })) {
          columnDefs.push({
            field: "Comment",
            name: "Comment" + uniqueId(),
            priority: 999,
            displayName: "Comment",
            minWidth: 125,
            headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/text",
            cellTemplate: "ui-grid/uiGridCell/text",
            editableCellTemplate: "ui-grid/cellEditor/text"
          });
        }
        // add accountingDate column to table and should be always visible
        if (!_.find(columnDefs, function (def) { return def.field === "AccountingDate"; })) {
          columnDefs.push({
            field: "AccountingDate",
            name: "AccountingDate" + uniqueId(),
            priority: 1000,
            displayName: "Accounting Date",
            minWidth: 100,
            cellFilter: "date:'dd.MM.yyyy'",
            headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/date",
            cellTemplate: "ui-grid/uiGridCell/text",
            editableCellTemplate: "ui-grid/cellEditor/date"
          });
        }

        return map;
      });

      addMissingDimensionsToRows(columnDefs, service.mappedTableData);
      return service.mappedTableData;
    }

    function addMissingDimensionsToRows(columnDefs, mappedTableData) {
        const permanentKeys = ['Id', 'Description', 'SumWithoutVat', 'VAT', 'Total', 'OrderNo', 'VatRate', 'VatCode', 'Account', 'AccountingDate', 'Comment'];
        /**
         * get codes of all customFields that were added to columnDefs
         */
        const defs = columnDefs.filter((colDef) => colDef.priority > 10 && colDef.priority < 999).map((colDef) => colDef.field);

        /**
         * Go over the already mapped data, to attach any "invisible" custom fields to all rows that did not have these customFields already added.
         */
        mappedTableData.forEach((hzRow) => {
            const rowFields = _.difference(Object.keys(hzRow), permanentKeys);

            const hashKeysIndex = rowFields.indexOf('$$hashKey');
            if (hashKeysIndex !== -1) {
                rowFields.splice(hashKeysIndex, 1);
            }
            if (defs.length !== rowFields.length) {
                const missingFieldsNames = _.difference(defs, rowFields);
                const missingFields = columnDefs.filter((colDef) => missingFieldsNames.includes(colDef.field));
                missingFieldsNames.forEach((key, index) => {
                    const customCostObjective = service.customCostObjectives.find((cft) => cft.Id === missingFields[index].customCostObjectiveId);
                    if ( customCostObjective ) {
                        hzRow[key] = {
                            original: {},
                            Id: hzRow.Id + "_" + customCostObjective.Description,
                            CustomCostObjectiveId: customCostObjective.Id,
                            FieldValue: null,
                            Dimension: null,
                            CustomCostObjective: {...customCostObjective}
                        };
                    }
                })
            }
        });
        return mappedTableData;
    }

    /*
        Takes in a row as a grid data structure and transforms it back into regular transaction rows format.
     */
    function unparseAccountantRows (row, invoice) {
      // parse out filled customFields from grid data row
      var customFields = Object.keys(row).filter(function (key) {
        return row[key] && typeof row[key] === "object" && row[key].DimensionId;
      }).map(function (key) {
        row[key].Id = parseInt(row[key].DimensionId.toString().split('_')[0]);
        return row[key];
      });
      return {
        Account: row.Account,
        AccountId: row.Account ? row.Account.Id : null,
        AccountingDate: row.AccountingDate.value,
        Comment: row.Comment.value,
        Description: row.Description.value,
        TransactionRowsDimensions: customFields,
        Id: row.Id,
        InvoiceId: invoice ? invoice.Id : null,
        OrderNo: row.OrderNo,
        SumWithoutVat: row.SumWithoutVat.value,
        VatCode: row.VatCode,
        VatCodeId: row.VatCodeId,
        Total: row.Total.value,
        VAT: row.VAT.value
      };
    }

    /*
        Save account field to BE
        @accountId - selected account Id
        @rowId - row Id
     */
    function saveAccount (accountId, rowId) {
      return webServices.UpdateAccount(accountId, rowId).catch(function () {
        notifyService.error("controller.invoiceConfirmationController.Incorrect_field_mask",
          "controller.invoiceConfirmationController.Error",
          true);
      });
    }

    function updateCustomFieldsValues (newCustomFields, mappedData) {
      var newColumn = false;
      if (newCustomFields && newCustomFields.length) {
        for (var i = 0; i < mappedData.length; i += 1) {
          var item = mappedData[i];
          if (item.Id === newCustomFields[0].InvoiceId) {
            newCustomFields.forEach(function (customField) {
              var key = customField.CustomCostObjective.Id.toString();
              if (!item[key]) {
                item[key] = {};
                newColumn = cloneDeep(customField);
              }
              // preserve the itemCount from original value, because saving does not return this field
              const dimensionsCount = item[key] && item[key].CustomCostObjective ? item[key].CustomCostObjective.DimensionsCount : 0;
              item[key].Id = customField.Id;
              item[key].original = customField;
              item[key].CustomCostObjectiveId = customField.CustomCostObjectiveId;
              item[key].DimensionId = customField.DimensionId;
              item[key].CustomCostObjective = customField.CustomCostObjective;
              item[key].Dimension = customField.Dimension;
              item[key].CustomCostObjective.DimensionsCount = dimensionsCount;
            });
            break;
          }
        }
      }
      if (newColumn) {
          return newColumn;
      }
    }

    /*
        Save custom field to BE
        @customField - data of the new customField object
        @rowId - row Id
     */
    function saveCustomField (customField, rowId) {
      if (!customField.DimensionId) {
        return webServices.UpdateCustomField({
            CustomCostObjectiveId: customField.CustomCostObjective.Id,
            CustomCostObjective: null
        }, rowId).then(
            function (response) {
                return updateCustomFieldsValues(response.data, service.mappedTableData);
            }
        )
      }
      return webServices.UpdateCustomFieldWithRelations(customField, rowId, $rootScope.companyData.CompanyId)
        .then(function (response) {
          return updateCustomFieldsValues(response.data, service.mappedTableData);
        }, function (error) {
        })
        .catch(function () {
          notifyService.error("controller.invoiceConfirmationController.Incorrect_field_mask",
            "controller.invoiceConfirmationController.Error",
            true);
        });
    }

    /*
        Creates request object to save accountingRows. Used to save description, sum, vat or sum with vat
     */
    function saveRow (row, invoice) {
      return webServices.updateTransactionRow(
        unparseAccountantRows(row, invoice)
        , invoice.Id
      );
    }

    /*
        Save accounting date for transaction row
     */
    function saveDate (row) {
      return webServices.updateDate(row.Id, row.AccountingDate ? formatDate(row.AccountingDate.value, 'yyyy-MM-dd') : null);
    }

    /*
        Save accounting comment for transaction row
     */
    function saveComment (row) {
      return webServices.updateComment(row.Id, row.Comment ? row.Comment.value : "");
    }

    /*
        Save the vatCode
     */
    function saveVatCode (row, invoice) {
      var request = {
        Id: row.Id,
        SumWithoutVat: row.SumWithoutVat.value,
        VAT: row.VAT.value,
        VatCodeId: row.VatCode ? row.VatCode.Id : null
      };
      return webServices.updateVatCode(request, invoice.Id).then(
        function (response) {
          return {
            Id: response.data.Id,
            SumWithoutVat: response.data.SumWithoutVat,
            VAT: response.data.VAT,
            VatCode: response.data.VatCode,
            VatCodeId: response.data.VatCodeId,
          };
        }
      );
    }

    /*
        get all customField from row entity
     */
    function getRowCustomFields (row) {
      return _.filter(_.values(row), function (value) { return value && value.CustomCostObjectiveId !== undefined && value.Id !== undefined; });
    }

    /*
        Copys the selection on parameters in accountingRowDTO to all the rows
     */
    function copyToAllTransactionRows (accountingRowDTO, id, orderNo) {
      return webServices.copyToAllTransactionRows(accountingRowDTO, id, orderNo);
    }

    /*
        Add columns to columnDefs for each customField
        Returns added customFields just incase we want to do something extra with that data
     */
    function addColumnBasedOnCustomFields (customFields, columnDefs, rowEntity) {
      _.forEach(customFields, function (customField) {
        // if row doesn't have this customField object yet, then add it. Used to keep columnDefs and rows in sync
        if (rowEntity && !rowEntity[customField.CustomCostObjective.Id.toString()]) {
          rowEntity[customField.CustomCostObjective.Id.toString()] = {
            Id: customField.Id,
            CustomCostObjectiveId: customField.CustomCostObjectiveId,
            DimensionId: customField.DimensionId,
            CustomCostObjective: customField.CustomCostObjective,
          };
        }
        if (!_.find(columnDefs, function (def) { return def.field === customField.CustomCostObjective.Id.toString(); })) {
          columnDefs.push({
            field: customField.CustomCostObjective.Id.toString(),
            name: customField.CustomCostObjective.Description + uniqueId(),
            priority: 100,
            displayName: customField.CustomCostObjective.Description,
            customCostObjectiveId: customField.CustomCostObjective.Id,
            orderNo: customField.CustomCostObjective.OrderNo,
            minWidth: 125,
            headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/typeahead",
            cellTemplate: "ui-grid/uiGridCell/dimension-typeahead",
            editableCellTemplate: "ui-grid/cellEditor/dimension-typeahead",
            refParameter: "Id",
            refAsParameter: "Description",
            typeaheadOptions: getCustomDimensions,
            dimensionsCount: customField.CustomCostObjective.DimensionsCount,
            filterFunction: filterCustomFields
          });
        }
      });
      // recalculate ellipsis when we add columns based on customFields and check if emptyMandatoryFieldCount changed
      setTimeout(function () {
        $rootScope.$emit("calculateEmptyMandatoryFields");
        $rootScope.$emit("buildEllipsis");
      });
      columnDefs = sortColumnDefs(columnDefs); // sort columns
      return customFields;
    }

    /*
        Check if any of the cells in this column are mandatory or visible
     */
    function isColumnMandatory (col) {
      var mandatoryObject = {
        mandatoryByCompany: false,
        mandatoryByAccount: false,
        visible: false
      };
      _.forEach(service.mappedTableData, function (row) {
        // check if column is mandatory set by the company settings
        if (_.find(service.customCostObjectives, function (t) { return t.Id === col.customCostObjectiveId && t.IsMandatory; })) {
          mandatoryObject.mandatoryByCompany = true;
        }
        // check if column is mandatory set by selected account
        if (row.Account && _.find(row.Account.AdditionalFields, function (a) { return a.CustomCostObjectiveId === col.customCostObjectiveId; })) {
          mandatoryObject.mandatoryByAccount = true;
        }
        // check if column is visible set by the company settings
        if (_.find(service.customCostObjectives, function (t) { return t.Id === col.customCostObjectiveId && t.IsVisible; })) {
          mandatoryObject.visible = true;
        }
      });
      return mandatoryObject;
    }

    /*
        Sort columns based on priority so the order would be:
        For customFields takes into account IsMandatory and IsVisible values
        1) Pinned columns(editRow, Description, Price, VAT, Total)
        2) VatCode
        3) Account
        4) Custom dimensions
            a) Mandatory from company settings
            b) Mandatory from selected account
            c) Visible from company settings
            d) Optional
        5) Comment
        6) Accounting date
        TODO this is a very taxing on the performance. Should optimize this at some point
     */
    function sortColumnDefs (columnDefs) {
      return columnDefs.sort(function (a, b) {
        if (a.priority && b.priority) {
          if (a.priority < b.priority) {
            return -1;
          } else if (a.priority > b.priority) {
            return 1;
          } else {
            // var isAMandatory = isColumnMandatory(a),
            //     isBMandatory = isColumnMandatory(b);
            // if (isAMandatory.mandatoryByCompany && !isBMandatory.mandatoryByCompany) {
            //     return -1;
            // } else if (!isAMandatory.mandatoryByCompany && isBMandatory.mandatoryByCompany) {
            //     return 1;
            // } else if (isAMandatory.mandatoryByCompany && isBMandatory.mandatoryByCompany) {
            //     return 0;
            // } else if (isAMandatory.mandatoryByAccount && !isBMandatory.mandatoryByAccount) {
            //     return -1;
            // } else if (!isAMandatory.mandatoryByAccount && isBMandatory.mandatoryByAccount) {
            //     return 1;
            // } else if (isAMandatory.mandatoryByAccount && isBMandatory.mandatoryByAccount) {
            //     return 0;
            // } else if (isAMandatory.visible && !isBMandatory.visible) {
            //     return -1;
            // } else if (!isAMandatory.visible && isBMandatory.visible) {
            //     return 1;
            // } else {
            //     return 0;
            // }
            if (a.orderNo > b.orderNo) {
              return 1;
            } else if (a.orderNo < b.orderNo) {
              return -1;
            } else {
              return 0;
            }
          }
        } else {
          return 0;
        }
      });
    }
  }
})();