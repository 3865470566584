(function () {
  "use strict";

  angular.module("dstreamApp.views.detailed-transactions")
    .factory("detailedTransactionsTableTemplates", detailedTransactionsTableTemplates);

  detailedTransactionsTableTemplates.$inject = ["$templateCache"];

  function detailedTransactionsTableTemplates ($templateCache) {
    var service = {};

    service.$init = function () {
      /*
          ROW SPECIFIC
       */
      $templateCache.put("ui-grid/ui-grid-row",
          `<div data-ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by $index">
                <div ui-grid-one-bind-id-grid="rowRenderIndex + '-' + col.uid + '-cell'"
                    class="ui-grid-cell"
                    data-id="col.{{col.field}}.{{$index}}"
                    ng-class="{ 'ui-grid-row-header-cell': col.isRowHeader, 'is-loading': grid.getCellValue(row, col).loading}"
                    role="{{col.isRowHeader ? 'rowheader' : 'gridcell'}}"
                    ui-grid-cell>
               </div>
           </div>`
      );

      $templateCache.put("ui-grid/selectionRowHeaderButtons",
          `<div class="ui-grid-selection-row-header-buttons ui-grid-icon-ok clickable"
                data-id="{{'hz-table--row_' + rowRenderIndex}}"
                ng-class="{'ui-grid-row-selected': row.isSelected}"
                ng-click="selectButtonClick(row, $event)"
                ng-keydown="selectButtonKeyDown(row, $event)"
                role="checkbox"
                ng-model="row.isSelected">
                &nbsp;
          </div>`
      );

      $templateCache.put("ui-grid/uiGridCell/rowEdit",
          `
        <div class="ui-grid-cell-contents">
            <button type="button"
                    class="btn btn--icon-only btn--sm"
                    data-id="{{'hz-table-edit-row_' + rowRenderIndex}}"
                    data-uib-popover-template="'ui-grid/rowEditPopover'"
                    data-popover-append-to-body="true"
                    data-ng-class="{'active': isOpen}"
                    data-popover-class="popover--md popover--shift-xs popover--arrow-xs"
                    data-popover-placement="auto right-top"
                    data-popover-is-open="isOpen"
                    data-popover-trigger="'outsideClick'"
                    analytics-on="click"
                    analytics-event="Table actions"
                    analytics-properties="{ category: 'Horizontal transactions', label: 'Toggle row edit popover' }">
                <svg class="icon btn__icon icon--sm">
                    <use xlink:href="#icon-vertical-pencil"/>
                </svg>
            </button>
        </div>
        `
      );

      $templateCache.put("ui-grid/rowEditPopover",
        `
        <div class="popover__inner-wrap popover__inner-wrap--no-margin">
            <ul class="popover__list">
                <li>
                    <button type="button"
                            class="popover__list-button"
                            data-id="{{ 'hz-table-edit-split-row_' + rowRenderIndex }}"
                            data-ng-click="grid.appScope.openSplitModal(row.entity); this.$parent.isOpen = false;"
                            analytics-on="click"
                            analytics-event="Table actions"
                            analytics-properties="{ category: 'Horizontal transactions', label: 'Open split row modal' }">
                        <svg class="icon">
                            <use xlink:href="#icon-split"/>
                        </svg>
                        <span>
                            {{'component.transactionRow.split.header' | translate}}
                        </span>
                    </button>
                </li>
            <!--
                <li>
                <button type="button" class="popover__list-button"
                            data-id="{{ 'hz-table-edit-row_' + rowRenderIndex }}">
                <svg class="icon">
                <use xlink:href="#icon-reset"/>
                </svg>
                <span>{{'views.detailedTransactions.table.resetRow' | translate}}</span>
                </button>
                </li>
             -->
                <li>
                    <button type="button"
                            class="popover__list-button"
                            data-id="{{ 'hz-table-edit-delete-row_' + rowRenderIndex }}"
                            data-ng-click="grid.appScope.deleteRowPopover.show(row.entity);"
                            uib-popover-template="grid.appScope.deleteRowPopover.template"
                            data-popover-class="popover--md popover--arrow-lg"
                            data-popover-placement="auto right"
                            data-popover-is-open="row.entity.showDeletePopover"
                            data-popover-append-to-body="true"
                            data-popover-trigger="'outsideClick'"
                            analytics-on="click"
                            analytics-event="Table actions"
                            analytics-properties="{ category: 'Horizontal transactions', label: 'Delete row' }">
                        <svg class="icon">
                            <use xlink:href="#icon-delete"/>
                        </svg>
                        <span>
                            {{'component.invoiceRows.deleteRow' | translate}}
                        </span>
                    </button>
                </li>
                <li>
                    <button type="button"
                            class="popover__list-button"
                            data-id="{{ 'hz-table-edit-copy-row_' + rowRenderIndex }}"
                            data-ng-click="grid.appScope.copyContentToRowsBelow(row.entity); this.$parent.isOpen = false;"
                            analytics-on="click"
                            analytics-event="Table actions"
                            analytics-properties="{ category: 'Horizontal transactions', label: 'Copy content to rows below' }">
                        <svg class="icon">
                            <use xlink:href="#icon-copy-content"/>
                        </svg>
                        <span>
                            {{'component.transactionRows.copyContent' | translate}}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
        `
      );

      $templateCache.put("detailed-transactions-row-delete-template.html",
        `
        <div class="popover__inner-wrap">
            <label>
                {{ 'component.transactionRows.deleteRowConfirmation' | translate }}
            </label>
            <p class="action-wrap action-wrap--right">
                <button class="btn btn--secondary btn--sm"
                        data-id="{{ 'hz-table-delete-cancel-row_' + rowRenderIndex }}"
                        data-ng-click="grid.appScope.deleteRowPopover.cancel(row.entity)" >
                    {{ 'views.global.No' | translate }}
                </button>
                <button class="btn btn--primary btn--sm"
                        data-id="{{ 'hz-table-delete-confirm-row_' + rowRenderIndex }}"
                        data-ng-click="grid.appScope.deleteRowPopover.delete(row.entity);" >
                    {{ 'views.global.Yes' | translate }}
                </button>
            </p>
        </div>
        `
    );

      /*
          CELL SPECIFIC
       */
      $templateCache.put("ui-grid/uiGridCell/description",
        `
        <div class="ui-grid-cell-contents"
            title="TOOLTIP"
            data-id="{{ 'hz-table-description-row_' + rowRenderIndex }}"
            data-uib-tooltip-html="COL_FIELD.value"
            data-tooltip-class="tooltip--mimic-popover tooltip--arrow-sm"
            data-tooltip-placement="auto bottom-left"
            data-tooltip-append-to-body="true"
            data-tooltip-trigger="'mouseenter'"
            data-tooltip-popup-delay="1000"
            data-ds-ellipsis
            data-ng-bind="COL_FIELD.value CUSTOM_FILTERS">
        </div>
        `
      );

      $templateCache.put("ui-grid/uiGridCell/number",
        `
        <div class="ui-grid-cell-contents align-right"
            data-id="{{'hz-table-number_cell_' + rowRenderIndex + '_' + colRenderIndex }}"
            data-uib-tooltip="{{ 'views.detailedTransactions.table.fillVatFirst' | translate }}"
            data-tooltip-class="tooltip--mimic-popover tooltip--arrow-xxs"
            data-tooltip-placement="auto left-top"
            data-typeahead-show-results-on-focus="true"
            data-tooltip-enable="!row.entity.VatCode && col.colDef.dependantOnVAT"
            data-tooltip-append-to-body="true"
            data-tooltip-trigger="'mouseenter'"
            title="TOOLTIP">
            {{COL_FIELD.value | number:2 CUSTOM_FILTERS}}
        </div>
        `
      );

        $templateCache.put("ui-grid/uiGridCell/netVatTotal",
            `
        <div class="ui-grid-cell-contents align-right"
            data-id="{{'hz-table-number_cell_' + rowRenderIndex + '_' + colRenderIndex }}"
            data-uib-tooltip="{{ 'views.detailedTransactions.table.fillVatFirst' | translate }}"
            data-tooltip-class="tooltip--mimic-popover tooltip--arrow-xxs"
            data-tooltip-placement="auto left-top"
            data-typeahead-show-results-on-focus="true"
            data-tooltip-enable="!row.entity.VatCode && col.colDef.dependantOnVAT"
            data-tooltip-append-to-body="true"
            data-tooltip-trigger="'mouseenter'"
            title="TOOLTIP">
            {{ grid.appScope.formatNetVatTotal(COL_FIELD.value) CUSTOM_FILTERS}}
        </div>
        `
        );

      $templateCache.put("ui-grid/uiGridCell/text",
        `
        <div data-id="{{'hz-table-text_cell_' + rowRenderIndex + '_' + colRenderIndex }}"
            class="ui-grid-cell-contents"
            data-ds-ellipsis
            title="TOOLTIP"
            data-ng-bind="COL_FIELD.value CUSTOM_FILTERS">
        </div>
        `
      );

      $templateCache.put("ui-grid/uiGridCell/typeahead",
        `
        <div class="ui-grid-cell-contents ui-grid-cell-contents--typeahead"
             data-id="{{'hz-table-typeahead_cell_' + rowRenderIndex + '_' + colRenderIndex }}"
             data-ng-class="{'is-mandatory': !COL_FIELD, 'is-empty': !COL_FIELD, 'ui-grid-cell-contents--typeahead': col.colDef.dimensionsCount > 0, 'is-error': col.colDef.dimensionsCount == 0 && !COL_FIELD}"
             title="TOOLTIP"
             data-uib-tooltip-html="COL_FIELD[col.colDef.refParameter] CUSTOM_FILTERS"
             data-tooltip-class="tooltip--mimic-popover tooltip--arrow-xxs"
             data-tooltip-placement="auto left-top"
             data-tooltip-enable="COL_FIELD"
             data-tooltip-append-to-body="true"
             data-tooltip-popup-delay="1000"
             data-tooltip-trigger="'mouseenter'" >
             <span
                class="ui-grid-cell-ellipsis"
                data-ng-if="col.colDef.dimensionsCount > 0 || COL_FIELD"
                data-ds-ellipsis
                data-ng-bind="COL_FIELD ? ((COL_FIELD.Code + ' - ' + COL_FIELD[col.colDef.refParameter]) CUSTOM_FILTERS) : col.displayName"
             >
            </span>
            <span
                class="ui-grid-cell-no-dimensions ui-grid-cell-ellipsis"
                data-ng-if="!grid.appScope.isFinalConfirmation() && col.colDef.dimensionsCount == 0 && !COL_FIELD"
                data-uib-tooltip="{{'component.transactionRows.DimensionsLimited' | translate}}"
                data-tooltip-placement="auto left-top"
                data-tooltip-append-to-body="true"
                data-tooltip-popup-delay="1000"
                data-tooltip-trigger="'mouseenter'"
                data-ds-ellipsis
                data-ng-bind="'component.transactionRows.NoDimensionsAvailable' | translate"
            >
            </span>
            <ds-legacy-error-notification
                data-ng-if="grid.appScope.isFinalConfirmation() && col.colDef.dimensionsCount == 0 && !COL_FIELD"
                message="'component.transactionRows.DimensionsLimited' | translate"
                in-table="true"
                data-uib-tooltip="{{'component.transactionRows.DimensionsLimited' | translate}}"
                data-tooltip-placement="auto left-top"
                data-tooltip-append-to-body="true"
                data-tooltip-popup-delay="1000"
                data-tooltip-trigger="'mouseenter'"
             >
            </ds-legacy-error-notification>

            <svg class="icon ui-grid__icon ui-grid__icon--typeahead" data-ng-if="col.colDef.dimensionsCount > 0">
                <use xlink:href="#icon-arrow_down"/>
            </svg>
        </div>
        `
      );

      $templateCache.put("ui-grid/uiGridCell/dimension-typeahead",
        `
        <div class="ui-grid-cell-contents"
             data-id="{{'hz-table-dimension_typeahead_cell_' + rowRenderIndex + '_' + colRenderIndex }}"
             data-ng-class="{'is-mandatory': (!COL_FIELD || !COL_FIELD.DimensionId) && grid.appScope.isCellMandatory(col, row), 'is-empty': (!COL_FIELD || !COL_FIELD.DimensionId), 'is-loading': COL_FIELD && COL_FIELD.loading, 'ui-grid-cell-contents--typeahead': grid.appScope.hasDimensions(COL_FIELD),'is-error': grid.appScope.isFinalConfirmation() && !grid.appScope.hasDimensions(COL_FIELD) && !COL_FIELD.Dimension && grid.appScope.isCellMandatory(col, row)}"
             title="TOOLTIP"
             data-uib-tooltip-html="grid.appScope.getDimensionNameById(COL_FIELD.DimensionId, COL_FIELD) CUSTOM_FILTERS"
             data-tooltip-class="tooltip--mimic-popover tooltip--arrow-xxs"
             data-tooltip-placement="auto left-top"
             data-tooltip-enable="COL_FIELD"
             data-tooltip-append-to-body="true"
             data-tooltip-popup-delay="1000"
             data-tooltip-trigger="'mouseenter'"
         >
            <span
                class="ui-grid-cell-ellipsis"
                data-ng-if="grid.appScope.hasDimensions(COL_FIELD) || COL_FIELD.Dimension"
                data-ds-ellipsis
                data-ng-bind="COL_FIELD && COL_FIELD.DimensionId ? (grid.appScope.getDimensionNameById(COL_FIELD.DimensionId, COL_FIELD) CUSTOM_FILTERS) : col.displayName"
            >
            </span>

            <ds-legacy-error-notification
                data-ng-if="grid.appScope.isFinalConfirmation() && !grid.appScope.hasDimensions(COL_FIELD) && !COL_FIELD.Dimension && grid.appScope.isCellMandatory(col, row)"
                message="'component.transactionRows.DimensionsLimited' | translate"
                in-table="true"
                data-uib-tooltip="{{'component.transactionRows.DimensionsLimited' | translate}}"
                data-tooltip-placement="auto left-top"
                data-tooltip-append-to-body="true"
                data-tooltip-popup-delay="1000"
                data-tooltip-trigger="'mouseenter'"
             >
            </ds-legacy-error-notification>

            <svg class="icon ui-grid__icon ui-grid__icon--typeahead">
                <use xlink:href="#icon-arrow_down"/>
            </svg>
        </div>`
      );

      /*
          CELL EDIT SPECIFIC
       */
      $templateCache.put("ui-grid/cellEditor/number",
        `
        <div class="ui-grid__full-height">
            <form name="inputForm"
                  class="ui-grid__full-height">
                <input type="text"
                       class="align-right ui-grid__input"
                       autocomplete="off"
                       auto-focus
                       data-id="{{ 'hz-table-number_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-only-digits
                       data-ng-class="'colt' + col.uid"
                       data-do-on-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);"
                       data-do-on-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);"
                       data-do-on-shift-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);"
                       data-do-on-shift-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);"
                       data-do-on-input-save="grid.appScope.onCelleditSelect($parent);"
                       data-do-on-input-cancel="grid.appScope.onCelleditCancel($parent);"
                       data-ng-model="MODEL_COL_FIELD.value"
                       data-ng-model-options="{ updateOn: 'blur' }">
            </form>
        </div>
        `
      );

      $templateCache.put("ui-grid/cellEditor/money",
        `
        <div class="ui-grid__full-height">
            <form name="inputForm" class="ui-grid__full-height">
                <input type="text"
                       ng-class="'colt' + col.uid"
                       class="align-right ui-grid__input"
                       autocomplete="off"
                       data-only-digits-two-after-comma=''
                       auto-focus
                       data-id="{{ 'hz-table-money_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-do-on-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);"
                       data-do-on-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);"
                       data-do-on-shift-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);"
                       data-do-on-shift-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);"
                       data-do-on-input-save="grid.appScope.onCelleditSelect($parent);"
                       data-do-on-input-cancel="grid.appScope.onCelleditCancel($parent);"
                       ng-model="MODEL_COL_FIELD.value"
                       ng-model-options="{ updateOn: 'default' }">
            </form>
        </div>
        `
      );

        $templateCache.put("ui-grid/cellEditor/companySettingsNetVatTotal",
            `
        <div class="ui-grid__full-height">
            <form name="inputForm" class="ui-grid__full-height">
                <input type="text"
                       ng-class="'colt' + col.uid"
                       class="align-right ui-grid__input"
                       autocomplete="off"
                       data-only-digits-net-vat-total=""
                       auto-focus
                       data-id="{{ 'hz-table-money_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-do-on-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);"
                       data-do-on-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);"
                       data-do-on-shift-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);"
                       data-do-on-shift-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);"
                       data-do-on-input-save="grid.appScope.onCelleditSelect($parent);"
                       data-do-on-input-cancel="grid.appScope.onCelleditCancel($parent);"
                       ng-model="MODEL_COL_FIELD.value"
                       ng-model-options="{ updateOn: 'default' }">
            </form>
        </div>
        `
        );

      $templateCache.put("ui-grid/cellEditor/text",
        `
        <div class=\"ui-grid__full-height\">
            <form name=\"inputForm\" class=\"ui-grid__full-height\">
                <input type=\"text\"
                       ng-class=\"'colt' + col.uid\"
                       class=\"ui-grid__input\"
                       auto-focus
                       data-id="{{ 'hz-table-text_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-do-on-tab=\"grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);\"
                       data-do-on-enter=\"grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);\"
                       data-do-on-shift-tab=\"grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);\"
                       data-do-on-shift-enter=\"grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);\"
                       data-do-on-input-save=\"grid.appScope.onCelleditSelect($parent);\"
                       data-do-on-input-cancel=\"grid.appScope.onCelleditCancel($parent);\"
                       ng-model=\"MODEL_COL_FIELD.value\"
                       ng-model-options=\"{ updateOn: 'blur' }\">
            </form>
        </div>
        `
      );

      $templateCache.put("ui-grid/cellEditor/date",
        `
        <div class="ui-grid__full-height">
            <form name="inputForm" class="ui-grid__full-height">
                <input type="text"
                       id="accounting-datepicker-{{id}}"
                       data-id="{{ 'hz-table-date_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       class="input input--alt ui-grid__input"
                       autocomplete="off"
                       data-show-button-bar="false"
                       data-uib-datepicker-popup="dd.MM.yyyy"
                       data-is-open="true"
                       data-popup-placement="auto bottom-right"
                       data-datepicker-popup-template-url="ui-grid/datepickerPopup/popup"
                       data-ng-model="MODEL_COL_FIELD.value"
                       data-datepicker-options="grid.appScope.dateOptions"
                       data-shortcut-propagation="true"
                       data-on-open-focus="true"
                       data-datepicker-append-to-body="true"
                       data-ng-change="grid.appScope.onCelleditSelect($parent);"
                       data-do-on-typeahead-blur="grid.appScope.onCelleditCancel($parent);"
                       auto-focus/>
            </form>
        </div>
        `
      );

      $templateCache.put("ui-grid/cellEditor/typeahead",
        `
        <div class="ui-grid__full-height">
            <form name="inputForm" class="ui-grid__full-height">
                <input type="text"
                       class="input input--alt ui-grid__input"
                       autocomplete="off"
                       data-id="{{ 'hz-table-typeahead_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-ng-model="MODEL_COL_FIELD"
                       data-uib-typeahead="option[col.colDef.refParameter] as (option.Code + ' - ' + option[col.colDef.refAsParameter]) for option in col.colDef.typeaheadOptions($viewValue, MODEL_COL_FIELD)"
                       data-typeahead-min-length="0"
                       data-typeahead-editable="false"
                       data-typeahead-show-results-on-focus="true"
                       data-typeahead-focus-on-select="false"
                       data-typeahead-input-formatter="MODEL_COL_FIELD ? (MODEL_COL_FIELD.Code + ' - ' + MODEL_COL_FIELD[col.colDef.refParameter]) : MODEL_COL_FIELD"
                       data-typeahead-popup-template-url="ui-grid/ui-grid-typeahead-popup.html"
                       data-typeahead-append-to-body="true"
                       data-typeahead-on-select="MODEL_COL_FIELD = $item; grid.appScope.onCelleditSelect($parent);"
                       auto-focus
                       data-do-on-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);"
                       data-do-on-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);"
                       data-do-on-shift-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);"
                       data-do-on-shift-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);"
                       data-do-on-typeahead-blur="grid.appScope.onCelleditCancel($parent);">
            </form>
        </div>
        `
      );

      $templateCache.put("ui-grid/cellEditor/dimension-typeahead",
        `
        <div class="ui-grid__full-height">
            <form name="inputForm" class="ui-grid__full-height">
                <input type="text"
                       name="customFieldValue"
                       class="input input--alt ui-grid__input"
                       autocomplete="off"
                       data-ng-model="MODEL_COL_FIELD"
                       data-id="{{ 'hz-table-number_input_' + rowRenderIndex + '_' + colRenderIndex }}"
                       data-uib-typeahead=" option[col.colDef.refParameter] as (option.Code + ' - ' + option[col.colDef.refAsParameter]) for option in col.colDef.typeaheadOptions($viewValue, col.colDef.customCostObjectiveId, MODEL_COL_FIELD)"
                       data-typeahead-min-length="0"
                       data-typeahead-editable="false"
                       data-typeahead-focus-on-select="false"
                       data-typeahead-show-results-on-focus="true"
                       data-typeahead-input-formatter="grid.appScope.getDimensionNameById($model.DimensionId, MODEL_COL_FIELD)"
                       data-typeahead-popup-template-url="ui-grid/ui-grid-typeahead-popup.html"
                       data-typeahead-append-to-body="true"
                       data-typeahead-on-select="MODEL_COL_FIELD = grid.appScope.updateDimension($item); grid.appScope.onCelleditSelect($parent);"
                       auto-focus
                       data-do-on-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('right', row, col);"
                       data-do-on-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('down', row, col);"
                       data-do-on-shift-tab="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('left', row, col);"
                       data-do-on-shift-enter="grid.appScope.onCelleditSelect($parent); grid.appScope.moveFocus('up', row, col);"
                       data-do-on-typeahead-blur="grid.appScope.onCelleditCancel($parent);">
            </form>
        </div>
        `
      );

      /*
          COLUMN HEADER SPECIFIC
       */
      $templateCache.put("ui-grid/uiGridHeaderCell",
        `
        <div role="columnheader"
             class="ui-grid__columnheader"
             ng-class="{ 'sortable': sortable }"
             ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
             aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}">
            <div role="button"
                 tabindex="0"
                 class="ui-grid-cell-contents ui-grid-header-cell-primary-focus"
                 data-id="{{ 'hz-table-col-head-button_' + renderIndex }}"
                 col-index="renderIndex"
                 title="TOOLTIP">
                <span class="ui-grid-header-cell-label"
                      ui-grid-one-bind-id-grid="col.uid + '-header-text'">
                    {{ col.displayName CUSTOM_FILTERS }}
                </span>
                <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'"
                      ui-grid-visible="col.sort.direction"
                      aria-label="{{getSortDirectionAriaLabel()}}">
                    <i ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
                       title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
                       aria-hidden="true"></i>
                    <sub ui-grid-visible="isSortPriorityVisible()"
                         class="ui-grid-sort-priority-number">
                        {{col.sort.priority + 1}}
                    </sub>
                </span>
            </div>
            <div role="button"
                 tabindex="0"
                 ui-grid-one-bind-id-grid="col.uid + '-menu-button'"
                 class="ui-grid-column-menu-button"
                 data-id="{{ 'hz-table-col-head-button_' + renderIndex }}"
                 data-ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false"
                 data-ng-click="toggleMenu($event)"
                 data-ng-class="{'ui-grid-column-menu-button-last-col': isLastCol}"
                 ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel"
                 aria-haspopup="true">
                <i class="ui-grid-icon-angle-down"
                   aria-hidden="true">
                    &nbsp;
                </i>
            </div>
            <div ui-grid-filter></div>
        </div>
        `
      );

      $templateCache.put("ui-grid/uiGridHeaderCellContentCopyable/typeahead",
        `
        <data-debounce-mousehover>
            <div role="columnheader"
                 class="ui-grid__columnheader"
                 ng-class="{ 'sortable': sortable }"
                 ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
                 aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}">
                <div data-ng-show="!col.hovering && !col.focused"
                     role="button"
                     tabindex="0"
                     class="ui-grid-cell-contents ui-grid-cell-contents__copyable ui-grid-header-cell-primary-focus"
                     data-id="{{ 'hz-table-col-head-button_' + renderIndex }}"
                     col-index="renderIndex"
                     title="TOOLTIP">
                    <span class="ui-grid-header-cell-label"
                          ui-grid-one-bind-id-grid="col.uid + '-header-text'">
                        {{ col.displayName CUSTOM_FILTERS }}
                    </span>
                    <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'"
                          ui-grid-visible="col.sort.direction"
                          aria-label="{{getSortDirectionAriaLabel()}}">
                        <i ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
                           title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
                           aria-hidden="true">
                        </i>
                        <sub ui-grid-visible="isSortPriorityVisible()"
                             class="ui-grid-sort-priority-number">
                            {{col.sort.priority + 1}}
                        </sub>
                    </span>
                </div>
                <input type="text"
                       name="copyableContent"
                       class="input input--alt ui-grid__header-input"
                       autocomplete="off"
                       data-id="{{ 'hz-table-col-head-typeahead_input_' + renderIndex }}"
                       data-ng-show="col.focused || col.hovering"
                       data-ng-class="{'ui-grid__header-input--focused': col.focused}"
                       data-ng-focus="col.focused = true"
                       data-ng-blur="col.focused = false"
                       data-ng-model="copyToCellsModel"
                       placeholder="{{ 'views.detailedTransactions.table.applyTheSameColum' | translate: {name: col.displayName} }}"
                       data-uib-typeahead="option[col.colDef.refParameter] as (option.Code + ' - ' + option[col.colDef.refAsParameter]) for option in col.colDef.typeaheadOptions($viewValue, col.colDef.customCostObjectiveId)"
                       data-typeahead-min-length="0"
                       data-typeahead-editable="false"
                       data-typeahead-input-formatter="grid.appScope.getDimensionNameById($model.DimensionId, copyToCellsModel)"
                       data-typeahead-focus-on-select="false"
                       data-typeahead-show-results-on-focus="true"
                       data-typeahead-popup-template-url="ui-grid/ui-grid-typeahead-popup.html"
                       data-typeahead-append-to-body="true"
                       data-typeahead-on-select="copyToCellsModel = null; grid.appScope.copyColumnToAllRows($item, col); $event.stopPropagation();">
                <div role="button"
                     tabindex="0"
                     ui-grid-one-bind-id-grid="col.uid + '-menu-button'"
                     class="ui-grid-column-menu-button"
                     data-id="{{ 'hz-table-col-head-typeahead_button_' + renderIndex }}"
                     data-ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false"
                     data-ng-click="toggleMenu($event)"
                     data-ng-class="{'ui-grid-column-menu-button-last-col': isLastCol}"
                     ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel"
                     aria-haspopup="true">
                    <i class="ui-grid-icon-angle-down"
                       aria-hidden="true">&nbsp;</i>
                </div>
                <div ui-grid-filter></div>
                <svg class="icon ui-grid__icon ui-grid__icon--copy-content">
                    <use xlink:href="#icon-copy-content"/>
                </svg>
                <button type="button"
                        class="btn btn--alt ui-grid__remove"
                        data-id="{{ 'hz-table-col-delete_' + renderIndex }}"
                        data-ng-click="grid.appScope.removeColumn(col)"
                        analytics-on="click"
                        analytics-event="Table actions"
                        analytics-properties="{ category: 'Horizontal transactions', label: 'Remove column' }"
                        data-ng-if="(col.focused || col.hovering) && grid.appScope.isColumnRemovable(col)">
                    <svg class="icon">
                        <use xlink:href="#icon-close"/>
                    </svg>
                </button>
            </div>
        </data-debounce-mousehover>
        `
      );

      $templateCache.put("ui-grid/uiGridHeaderCellContentCopyable/text",
          `
        <data-debounce-mousehover>
            <div role="columnheader"
                 class="ui-grid__columnheader"
                 ng-class="{ 'sortable': sortable }"
                 ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
                 data-ng-attr-aria-sort="{{ col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other')) }}">
                <div data-ng-show="!col.hovering && !col.focused"
                     role="button"
                     tabindex="0"
                     class="ui-grid-cell-contents ui-grid-cell-contents__copyable ui-grid-header-cell-primary-focus"
                     data-id="{{ 'hz-table-col-head-button_' + renderIndex }}"
                     col-index="renderIndex"
                     title="TOOLTIP">
                    <span class="ui-grid-header-cell-label"
                          ui-grid-one-bind-id-grid="col.uid + '-header-text'">
                            {{ col.displayName CUSTOM_FILTERS }}
                    </span>
                    <span ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'"
                          ui-grid-visible="col.sort.direction"
                          aria-label="{{getSortDirectionAriaLabel()}}">
                        <i ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
                           title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
                           aria-hidden="true"></i>
                        <sub ui-grid-visible="isSortPriorityVisible()"
                             class="ui-grid-sort-priority-number">
                            {{col.sort.priority + 1}}
                        </sub>
                    </span>
                </div>
                <input type="text"
                       name="copyableContent"
                       class="input input--alt ui-grid__header-input"
                       autocomplete="off"
                       data-id="{{ 'hz-table-col-head-text_input_' + renderIndex }}"
                       data-ng-show="col.focused || col.hovering"
                       data-ng-class="{'ui-grid__header-input--focused': col.focused}"
                       data-ng-focus="col.focused = true"
                       data-ng-blur="grid.appScope.copyColumnToAllRows(copyToCellsModel, col); copyToCellsModel = null; col.focused = false"
                       data-ng-model="copyToCellsModel"
                       placeholder="{{ 'views.detailedTransactions.table.applyTheSameColum' | translate: {name: col.displayName} }}">
                <div role="button"
                     tabindex="0"
                     ui-grid-one-bind-id-grid="col.uid + '-menu-button'"
                     class="ui-grid-column-menu-button"
                     data-id="{{ 'hz-table-col-menu_button_' + renderIndex }}"
                     data-ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false"
                     data-ng-click="toggleMenu($event)"
                     data-ng-class="{'ui-grid-column-menu-button-last-col': isLastCol}"
                     ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel"
                     aria-haspopup="true">
                    <i class="ui-grid-icon-angle-down"
                       aria-hidden="true">
                        &nbsp;
                    </i>
                </div>
                <div ui-grid-filter></div>
                <svg class="icon ui-grid__icon ui-grid__icon--copy-content">
                    <use xlink:href="#icon-copy-content"/>
                </svg>
                <button type="button"
                        class="btn btn--alt ui-grid__remove"
                        data-id="{{ 'hz-table-col-delete_' + renderIndex }}"
                        data-ng-click="grid.appScope.removeColumn(col)"
                        analytics-on="click"
                        analytics-event="Table actions"
                        analytics-properties="{ category: 'Horizontal transactions', label: 'Remove column' }"
                        data-ng-if="(col.focused || col.hovering) && grid.appScope.isColumnRemovable(col)">
                    <svg class="icon">
                        <use xlink:href="#icon-close"/>
                    </svg>
                </button>
            </div>
        </data-debounce-mousehover>
        `,
      );

      $templateCache.put("ui-grid/uiGridHeaderCellContentCopyable/date",
        "<data-debounce-mousehover>" +
        "<div role=\"columnheader\" " +
          "class=\"ui-grid__columnheader\" " +
          "ng-class=\"{ 'sortable': sortable }\" " +
          "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
          "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
        "<div data-ng-show=\"!col.hovering && !col.focused\" " +
          "role=\"button\" " +
          "tabindex=\"0\" " +
          "class=\"ui-grid-cell-contents ui-grid-cell-contents__copyable ui-grid-header-cell-primary-focus\" " +
          "col-index=\"renderIndex\" " +
          "title=\"TOOLTIP\">" +
        "<span class=\"ui-grid-header-cell-label\" " +
          "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\">{{ col.displayName CUSTOM_FILTERS }}</span> " +
        "<span ui-grid-one-bind-id-grid=\"col.uid + '-sortdir-text'\" " +
          "ui-grid-visible=\"col.sort.direction\" " +
          "aria-label=\"{{getSortDirectionAriaLabel()}}\">" +
        "<i ng-class=\"{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }\" " +
          "title=\"{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}\" " +
          "aria-hidden=\"true\"></i> " +
        "<sub ui-grid-visible=\"isSortPriorityVisible()\" " +
          "class=\"ui-grid-sort-priority-number\">{{col.sort.priority + 1}}</sub>" +
        "</span>" +
        "</div>" +
        "<input type=\"text\" " +
        "class=\"input input--alt ui-grid__header-input\" " +
        "data-ng-show=\"col.focused || col.hovering\" " +
        "data-ng-class=\"{'ui-grid__header-input--focused': col.focused}\" " +
        "data-ng-focus=\"col.focused = true\" " +
        "data-show-button-bar=\"false\" " +
        "data-uib-datepicker-popup=\"dd.MM.yyyy\" " +
        "data-datepicker-popup-template-url=\"ui-grid/datepickerPopup/popup\" " +
        "placeholder=\"{{ 'views.detailedTransactions.table.applyTheSameColum' | translate: {name: col.displayName} }}\" " +
        "data-is-open=\"col.focused\" " +
        "data-popup-placement=\"auto bottom-right\" " +
        "data-ng-model=\"grid.appScope.dateOptions.columnValue\" " +
        "data-datepicker-options=\"grid.appScope.dateOptions\" " +
        "data-datepicker-append-to-body=\"true\" " +
        "data-on-open-focus=\"true\"/>" +
        "<div role=\"button\" tabindex=\"0\" ui-grid-one-bind-id-grid=\"col.uid + '-menu-button'\" class=\"ui-grid-column-menu-button\" ng-if=\"grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false\" ng-click=\"toggleMenu($event)\" ng-class=\"{'ui-grid-column-menu-button-last-col': isLastCol}\" ui-grid-one-bind-aria-label=\"i18n.headerCell.aria.columnMenuButtonLabel\" aria-haspopup=\"true\">" +
        "<i class=\"ui-grid-icon-angle-down\" aria-hidden=\"true\">&nbsp;</i>" +
        "</div>" +
        "<div ui-grid-filter></div>" +
        "<svg class=\"icon ui-grid__icon ui-grid__icon--copy-content\">" +
        "<use xlink:href=\"#icon-copy-content\"/>" +
        "</svg>" +
        "</div>" +
        "</data-debounce-mousehover>"
      );

      $templateCache.put("ui-grid/ui-grid-header",
          `<div role="rowgroup"
                class="ui-grid-header">
                <!-- theader -->
                <div class="ui-grid-top-panel">
                    <div class="ui-grid-header-viewport">
                        <div class="ui-grid-header-canvas">
                            <div class="ui-grid-header-cell-wrapper"
                                 ng-style="colContainer.headerCellWrapperStyle()">
                                <div role="row"
                                     class="ui-grid-header-cell-row">
                                    <div class="ui-grid-header-cell ui-grid-clearfix"
                                         ng-repeat="col in colContainer.renderedColumns track by $index"
                                         ui-grid-header-cell
                                         col="col"
                                         render-index="$index"></div>
                                    </div>
                                </div>
                            </div>
                        <div class="ui-grid-header-canvas ui-grid-header-canvas--virtual"
                             data-ng-style=""></div>
                    </div>
                </div>
            </div>`
      );

      /*
          GENERAL
       */
      $templateCache.put("ui-grid/ui-grid",
        `
        <div ui-i18n="en"
             class="ui-grid">
            <!-- TODO (c0bra): add "scoped" attr here, eventually? -->
            <style ui-grid-style>
                .grid{{ grid.id }} {
                    }
                .grid{{ grid.id }} .ui-grid-row,
                .grid{{ grid.id }} .ui-grid-cell,
                .grid{{ grid.id }} .ui-grid-cell .ui-grid-vertical-bar {
                  height: {{ grid.options.rowHeight }}px;
                }
                .grid{{ grid.id }} .ui-grid-row:last-child .ui-grid-cell {
                  border-bottom-width: {{ ((grid.getTotalRowHeight() ${'<'} grid.getViewportHeight()) ${'&&'} '1') || '0' }}px;
                }
                {{ grid.verticalScrollbarStyles }}
                {{ grid.horizontalScrollbarStyles }}
                /*
                .ui-grid[dir=rtl] .ui-grid-viewport {
                  padding-left: {{ grid.verticalScrollbarWidth }}px;
                }
                */
                {{ grid.customStyles }}
            </style>
            <div class="ui-grid-contents-wrapper" data-id="wrapper.contents">
                <div ui-grid-menu-button
                     ng-if="grid.options.enableGridMenu"></div>
                <div ng-if="grid.hasLeftContainer()"
                     style="width: 0"
                     ui-grid-pinned-container="'left'"></div>
                <div ui-grid-render-container container-id="'body'"
                     col-container-name="'body'"
                     row-container-name="'body'"
                     bind-scroll-horizontal="true"
                     bind-scroll-vertical="true"
                     enable-horizontal-scrollbar="grid.options.enableHorizontalScrollbar"
                     enable-vertical-scrollbar="grid.options.enableVerticalScrollbar"></div>
                <div ng-if="grid.hasRightContainer()"
                     style="width: 0"
                     ui-grid-pinned-container="'right'"></div>
                <div ui-grid-grid-footer
                     ng-if="grid.options.showGridFooter"></div>
                <div ui-grid-column-menu
                     ng-if="grid.options.enableColumnMenus"></div>
                <div ng-transclude></div>
            </div>
            <button type="button" class="btn btn--icon-only detailed-transactions__add-column"
                    data-id="hz-table-add-col"
                    data-uib-popover-template="grid.appScope.addNewColumnPopover.template"
                    data-popover-append-to-body="true"
                    data-popover-class="popover--md popover--arrow-xxs"
                    data-popover-placement="auto left-top"
                    data-popover-is-open="grid.appScope.addNewColumnPopover.open"
                    data-popover-trigger="'outsideClick'">
              <svg class="icon btn__icon icon--sm">
                <use xlink:href="#icon-plus"/>
              </svg>
            </button>
        </div>
        `
      );

      $templateCache.put("ui-grid/ui-grid-typeahead-popup.html",
        `
        <ul class="dropdown-menu ui-grid__typeahead"
            data-typeahead-position-ajustment
            data-id="{{ 'hz-typeahead_' + renderIndex }}"
            data-ng-show="isOpen() && !moveInProgress"
            data-ng-style="{top: position().top+'px', left: position().left+'px'}"
            role="listbox"
            data-ng-attr-aria-hidden="{{!isOpen()}}">
            <li class="uib-typeahead-match"
                data-ng-repeat="match in matches track by $index"
                data-ng-class="{active: isActive($index) }"
                data-ng-mouseenter="selectActive($index)"
                data-id="{{ 'hz-typeahead_' + renderIndex + '-item_' + $index }}"
                data-ng-click="selectMatch($index, $event)"
                role="option"
                id="{{::match.id}}">
                <div uib-typeahead-match
                     index="$index"
                     match="match"
                     query="query"
                     data-ng-attr-template-url="templateUrl">
                </div>
           </li>
        </ul>
        `
        );

      $templateCache.put("ui-grid/datepickerPopup/popup",
        "<ul role=\"presentation\" class=\"uib-datepicker-popup ui-grid__datepicker dropdown-menu uib-position-measure\" dropdown-nested ng-if=\"isOpen\" ng-keydown=\"keydown($event);\" ng-click=\"$event.stopPropagation()\">" +
        "  <li ng-transclude></li>" +
        "  <li ng-if=\"showButtonBar\" class=\"uib-button-bar\">" +
        "    <span class=\"btn-group pull-left\">" +
        "      <button type=\"button\" class=\"btn btn-sm btn-info uib-datepicker-current\" ng-click=\"select('today', $event)\" ng-disabled=\"isDisabled('today')\">{{ getText('current') }}</button>" +
        "      <button type=\"button\" class=\"btn btn-sm btn-danger uib-clear\" ng-click=\"select(null, $event)\">{{ getText('clear') }}</button>" +
        "    </span>" +
        "    <button type=\"button\" class=\"btn btn-sm btn-success pull-right uib-close\" ng-click=\"close($event)\">{{ getText('close') }}</button>" +
        "  </li>" +
        "</ul>" +
        "");

      $templateCache.put("ui-grid/addNewColumn",
        `
        <ng-form name="ui-grid_addNewColumn-form"
                 novalidate>
            <div class="popover__inner-wrap">
                <label>
                    {{'views.detailedTransactions.table.addNewColumnHeader' | translate}}
                </label>
                <input type="text"
                       name="columnName"
                       class="input"
                       autocomplete="off"
                       data-id="hz-table-add-col-name_input"
                       data-ng-class="{'error': $ctrl.addNewColumnPopover.invalid }"
                       data-ng-model="grid.appScope.addNewColumnPopover.newColumnValue"
                       data-uib-typeahead="customCostObjective as customCostObjective.Description for customCostObjective in grid.appScope.buildPossibleNewColumnsArray(grid.options.columnDefs) | filter:{Description: ($viewValue || '') }"
                       data-typeahead-min-length="0"
                       data-typeahead-editable="false"
                       data-typeahead-input-formatter="$model ? $model.Description : $model"
                       data-typeahead-focus-on-select="false"
                       data-typeahead-show-results-on-focus="true"
                       data-typeahead-on-select="grid.appScope.addNewColumnPopover.select($item, $event)"
                       required>
                <p class="action-wrap action-wrap--right">
                    <button class="btn btn--secondary btn--md"
                            data-id="'hz-table-add-col-cancel'"
                            data-ng-click="grid.appScope.addNewColumnPopover.cancel()">
                        {{"views.global.Cancel" | translate}}
                    </button>
                    <button class="btn btn--primary btn--md"
                            data-id="hz-table-add-col-confirm"
                            data-ng-click="grid.appScope.addNewColumnPopover.add(grid.appScope.addNewColumnPopover.newColumnValue)"
                            analytics-on="click"
                            analytics-event="Table actions"
                            analytics-properties="{ category: 'Horizontal transactions', label: 'Add column' }">
                        {{'views.detailedTransactions.table.addNewColumn' | translate}}
                    </button>
                </p>
            </div>
        </ng-form>
        `
      );
    };

    return service;
  }
})();