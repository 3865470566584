import React from 'react';
import withFormikField from '../../../common/utils/withFormikField';
import { MonetaryScalesInputProps } from './MonetaryPrecisionScalesInputTypes';

import { useCompanySetting } from '../../../common/hooks/useCompanySettings';
import { NumericFormat } from 'react-number-format';

import { ControlledInput } from './ControlledInput';
import { MONETARY_VAT_NET_VALID_CHARACTERS_REGEXP } from '../MonetaryPrecisionScalesHelper';

export const MonetaryPrecisionScalesInput: React.FC<MonetaryScalesInputProps> = (props) => {
    const { type, forceFixedDecimalScale, ...inputProps } = props;
    const { Value: decimalScale } = useCompanySetting('MonetaryNetVatTotalPrecision');
    return (
        <NumericFormat
            {...inputProps}
            customInput={ControlledInput}
            inputType={type}
            fixedDecimalScale={forceFixedDecimalScale}
            decimalScale={props.decimalScale || Number(decimalScale)}
            validCharacters={MONETARY_VAT_NET_VALID_CHARACTERS_REGEXP}
        />
    );
};

// Resolve input props for using as Formik Field component
export const MonetaryPrecisionScalesField = withFormikField(MonetaryPrecisionScalesInput);
