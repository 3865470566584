import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { TFunction } from 'i18next';
import { Dispatchable1, Dispatchable2 } from 'redux-dispatchers';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';

import { notify } from '../../common/utils/notify';
import { createDataId } from '../../common/utils/dataId';
import createFieldNameConstants from '../../common/utils/fieldNames';
import FormikField from '../../common/utils/FormikField';
import { SupplierDTO, SerbianCompanyType, SerbiaCompanyData, BaseSearch } from '../../services/types/ApiTypes';
import { Button, ButtonType } from '../Buttons/Button';
import Modal, { ModalType } from '../Modal/Modal';
import { ICONS } from '../Icon/Icon';
import { TextInputField, TextInputType } from '../TextInput/TextInput';
import { ALPHANUMERIC_SECOND_EXTENDED_REGEXP, ALPHANUMERIC_REGEXP, ALPHANUMERIC_EXTENDED_REGEXP } from '../../common/utils/validators';
import withSuspense from '../../common/hocs/withSuspense';
import { ContentBlockBody } from '../ContentBlock/ContentBlockBody';
import { ContentBlockFooter } from '../ContentBlock/ContentBlockFooter';
import { Select } from '../Select/Select';
import { SelectOptionValue } from '../Select/SelectOption';
import { getSupportedCountriesOfWorld } from '../../views/login/loginHelper';
import api from '../../services/ApiServices';
import { Typography } from '../Typography';
import { errorsMap } from './EditSupplierModalHelpers';

import './EditSupplierModal.scss';

export interface EditSupplierFields extends Pick<SupplierDTO, 'Country' | 'CompanyType' | 'Name' | 'RegistrationCode' | 'VatCode' | 'ContactPersonName' | 'Code' | 'Email' | 'BankAccount'> {}

export interface Props {
    activeSupplier: SupplierDTO;
    isModalOpen: boolean;
    currentCompanyCountry: string;
    suppliersSearchParams: BaseSearch;
}

export interface DispatchProps {
    saveSupplier: Dispatchable1<EditSupplierFields>;
    setModalOpen: Dispatchable1<boolean>;
    updateSuppliersList: Dispatchable2<number, number>;
}

export type EditSupplierProps = Props & DispatchProps & WithTranslation;

const dataId = 'editSupplier';

const getCompanyTypeOptions = (t: TFunction): SelectOptionValue<SerbianCompanyType>[] =>
    Object.keys(SerbianCompanyType)
        .filter((key) => !isNaN(Number(SerbianCompanyType[key])))
        .map((k) => ({
            value: SerbianCompanyType[k],
            text: t(`views.company.detail.Type.${k}`),
        }))
        .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

const initialValues: EditSupplierFields = {
    Country: null,
    CompanyType: SerbianCompanyType.Company,
    Name: '',
    RegistrationCode: '',
    VatCode: '',
    ContactPersonName: '',
    Code: '',
    Email: '',
    BankAccount: '',
};

const SERBIA_COUNTRY_CODE = 'RS';

export const EditSupplierModal = ({ activeSupplier, currentCompanyCountry, isModalOpen, setModalOpen, t, saveSupplier, updateSuppliersList, suppliersSearchParams }: EditSupplierProps) => {
    const [isSerbiaDataLoading, setIsSerbiaDataLoading] = useState<boolean>(false);
    const [values, setValues] = useState<EditSupplierFields>(initialValues);
    const [supplierToEdit, setSupplierToEdit] = useState<SupplierDTO>(null); // need to null activeSupplier if we are changing it with pre-filled data

    const countriesOptions = getSupportedCountriesOfWorld();
    const typeOptions = getCompanyTypeOptions(t);

    const { Country, CompanyType, Name, RegistrationCode, VatCode, Email } = createFieldNameConstants(initialValues);

    const validationSchema = Yup.object<Pick<SupplierDTO, 'Name' | 'Country' | 'CompanyType' | 'RegistrationCode' | 'ContactPersonName' | 'Code' | 'Email' | 'BankAccount'>>().shape({
        [Name]: Yup.string()
            .ensure()
            .nullable(true)
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.company.detail.SupplierName') })),
        [Country]: Yup.string().nullable(true),
        [CompanyType]: Yup.number().nullable(true),
        [RegistrationCode]: Yup.string()
            .ensure()
            .nullable(false)
            .when(['Country', 'CompanyType'], {
                is: (c, ct) => c === SERBIA_COUNTRY_CODE && ct === SerbianCompanyType.BudgetUser,
                then: Yup.string().matches(/^[0-9]{5,5}$/, t('views.company.detail.notCorrectFormat')),
            })
            .when(['Country', 'CompanyType'], {
                is: (c, ct) => c === SERBIA_COUNTRY_CODE && ct !== SerbianCompanyType.BudgetUser,
                then: Yup.string().matches(/^[0-9]{8,8}$/, t('views.company.detail.notCorrectFormat')),
            })
            .when('Country', {
                is: 'EE',
                then: Yup.string().matches(/^[A-Za-z0-9]{1,15}$/, t('views.company.detail.notCorrectFormat')),
                // removing special symbols check, but maybe we'll change our minds later (add forward slash to the list maybe as well /)
                // otherwise: Yup.string().matches(/^[a-zA-Z0-9 !?@#$%^&*)(+=._-]{1,50}$/, t('views.company.detail.notCorrectFormat')),
                otherwise: Yup.string().matches(/^.{1,50}$/, t('views.company.detail.notCorrectFormat')),
            })
            .required(t('view.Accounting.MandatoryField', { fieldName: t('views.company.detail.Registration_number') })),
        [Email]: Yup.string()
            .ensure()
            .nullable(true)
            .email(t('views.company.detail.email.InvalidFormat')),
    });

    useEffect(() => {
        setValues({ ...values, Country: currentCompanyCountry || 'null' });
    }, [currentCompanyCountry]);

    useEffect(() => {
        setSupplierToEdit(activeSupplier);
    }, [activeSupplier]);

    const closeModal = () => {
        // TODO: we need to reset values on modal close, because the component is still mounted on close (need to solve this)
        setValues({ ...initialValues, Country: currentCompanyCountry || 'null' });
        setModalOpen(false);
    };

    const handleSave = async (values: EditSupplierFields, formikActions: FormikActions<EditSupplierFields>) => {
        let Country = values.Country;
        let CompanyType = values.CompanyType;
        // we handle null cases here, to make sure correct values are saved
        if (values.Country === 'null' || values.Country === 'XX') {
            Country = null; // 'Other Country' case
        }
        if (values.Country !== SERBIA_COUNTRY_CODE) {
            CompanyType = null; // no support for CompanyType in countries (except Serbia) yet
        }

        try {
            const page = suppliersSearchParams?.PagingOptions?.Page || 1;
            const count = suppliersSearchParams?.PagingOptions?.Count || 15;
            console.log({ page, count, suppliersSearchParams });
            await saveSupplier({ ...values, Country, CompanyType });
            updateSuppliersList(page, count);
            closeModal();
        } catch (e) {
            const err = e as AxiosError<{ message: string }>;
            const { message } = err.response.data;
            const errorData = errorsMap.get(message);

            if (!errorData) {
                return notify.error(t('views.company.detail.Add_Company.errorSavingData'));
            }

            formikActions.setFieldError(errorData.fieldName, t(errorData.error));
        }
    };

    const getSerbianCompanyData = async (formik: FormikProps<EditSupplierFields>) => {
        // we get Supplier details from api and put in into the form
        setIsSerbiaDataLoading(true);
        try {
            const { data }: AxiosResponse<SerbiaCompanyData> = await api.company.getSerbiaCompanyData(formik.values[RegistrationCode], formik.values[CompanyType]);
            if (data === null) {
                throw new Error('noDataFound');
            }
            const { Name, VatNumber: VatCode, ContactPerson: ContactPersonName, Email, BankAccount } = data;
            const newValues = {
                ...values,
                ...formik.values,
                Name,
                VatCode,
                BankAccount,
                ContactPersonName,
                Email,
            };
            setValues(newValues);
            setSupplierToEdit(null);
        } catch (e) {
            if (e.message === 'noDataFound') {
                notify.warning(t('views.company.detail.Add_Company.noDataFound'));
            } else {
                console.error(e);
                notify.error(t('views.company.detail.Add_Company.errorLoadingData'));
            }
        } finally {
            setIsSerbiaDataLoading(false);
        }
    };

    const isFillButtonDisabled = (formik: FormikProps<EditSupplierFields>) => {
        return !(formik.values[CompanyType] && formik.values[Country] && formik.values[RegistrationCode] && !formik.errors[RegistrationCode]);
    };

    const isSaveButtonDisabled = (formik: FormikProps<EditSupplierFields>) => {
        return isSerbiaDataLoading || !isEmpty(formik.errors) || isEmpty(formik.values[RegistrationCode]) || isEmpty(formik.values[RegistrationCode]) || isEmpty(formik.values[Name]);
    };

    const isTypeAndPrefillRequestVisible = (formik: FormikProps<EditSupplierFields>): boolean => formik.values[Country] === SERBIA_COUNTRY_CODE;

    return (
        <Modal isOpen={isModalOpen} type={ModalType.FIXED_RIGHT} onClose={closeModal} className="edit-supplier" dataId="editSupplierModal">
            <Formik onSubmit={handleSave} initialValues={supplierToEdit || values} enableReinitialize={true} validateOnBlur={true} validateOnChange={true} validationSchema={validationSchema}>
                {(formik) => (
                    <Form className="edit-supplier__container">
                        <Typography variant="h1" element="h2" dataId={createDataId(dataId, 'title')} className="edit-supplier__title">
                            {activeSupplier?.Id ? t('views.company.detail.editCompany') : t('views.company.detail.Add_Company')}
                        </Typography>

                        <Select
                            className="country-select"
                            dataId={createDataId(dataId, 'country')}
                            items={countriesOptions}
                            value={countriesOptions.find((o) => o.value === formik.values[Country] || o.value === 'XX')}
                            label={t('views.company.detail.column.country')}
                            triggerIcon={ICONS.ARROW_DOWN_SMALL}
                            name={Country}
                            onChange={(country: SelectOptionValue<string>) => {
                                formik.setFieldValue(Country, country.value);
                            }}
                        />
                        {isTypeAndPrefillRequestVisible(formik) && (
                            <Select
                                className="type-select"
                                dataId={createDataId(dataId, 'type')}
                                items={typeOptions}
                                value={typeOptions.find((o) => o.value === parseInt(formik.values[CompanyType], 10))}
                                label={`${t('views.company.detail.column.type')} *`}
                                triggerIcon={ICONS.ARROW_DOWN_SMALL}
                                name={CompanyType}
                                onChange={(type: SelectOptionValue<SerbianCompanyType>) => {
                                    formik.setFieldValue(CompanyType, type.value);
                                }}
                            />
                        )}

                        <FormikField
                            maxLength={50}
                            dataId={createDataId(dataId, 'regNr')}
                            onlyChangeOnBlur={false}
                            component={TextInputField}
                            name={RegistrationCode}
                            type={TextInputType.BORDERED}
                            label={`${
                                formik.values?.[CompanyType] === SerbianCompanyType.BudgetUser && formik?.values.Country === 'RS'
                                    ? t('views.company.detail.BudgetUserNum')
                                    : t('views.company.detail.Registration_number')
                            } *`}
                        />
                        {isTypeAndPrefillRequestVisible(formik) && (
                            <Button
                                className="edit-supplier__check-button"
                                onClick={() => getSerbianCompanyData(formik)}
                                disabled={isFillButtonDisabled(formik)}
                                buttonType={ButtonType.SECONDARY}
                                dataId={createDataId(dataId, 'fillButton')}
                            >
                                {t('views.company.detail.fillDataFromRegister')}
                            </Button>
                        )}
                        <ContentBlockBody dataId={createDataId(dataId, 'fillableFormFields')} className="edit-supplier__body" loading={isSerbiaDataLoading}>
                            <FormikField
                                maxLength={100}
                                component={TextInputField}
                                name={Name}
                                dataId={createDataId(dataId, 'name')}
                                type={TextInputType.BORDERED}
                                label={`${t('views.company.detail.SupplierName')} *`}
                                onlyChangeOnBlur={false}
                            />

                            <FormikField
                                maxLength={15}
                                dataId={createDataId(dataId, 'vatNr')}
                                validCharacters={ALPHANUMERIC_REGEXP}
                                onlyChangeOnBlur={true}
                                component={TextInputField}
                                name={VatCode}
                                type={TextInputType.BORDERED}
                                label={t('views.company.detail.VAT_Nr')}
                            />

                            <FormikField
                                maxLength={50}
                                dataId={createDataId(dataId, 'erp')}
                                validCharacters={ALPHANUMERIC_SECOND_EXTENDED_REGEXP}
                                onlyChangeOnBlur={true}
                                component={TextInputField}
                                name="Code"
                                type={TextInputType.BORDERED}
                                label={t('views.company.detail.Supplier_code_in_ERP')}
                            />
                            <FormikField
                                maxLength={100}
                                dataId={createDataId(dataId, 'contactPerson')}
                                onlyChangeOnBlur={true}
                                component={TextInputField}
                                name="ContactPersonName"
                                type={TextInputType.BORDERED}
                                label={t('component.additionalInfo.supplierContactName')}
                            />
                            <FormikField
                                maxLength={254}
                                dataId={createDataId(dataId, 'email')}
                                onlyChangeOnBlur={false}
                                component={TextInputField}
                                name="Email"
                                type={TextInputType.BORDERED}
                                label={t('component.additionalInfo.supplierContactEmail')}
                            />
                            <FormikField
                                maxLength={35}
                                dataId={createDataId(dataId, 'bankAccount')}
                                validCharacters={ALPHANUMERIC_EXTENDED_REGEXP}
                                onlyChangeOnBlur={true}
                                component={TextInputField}
                                name="BankAccount"
                                type={TextInputType.BORDERED}
                                label={t('views.company.detail.BankAccount')}
                            />
                        </ContentBlockBody>
                        <ContentBlockFooter className="edit-supplier__footer">
                            <Button type="submit" disabled={isSaveButtonDisabled(formik)} className="edit-supplier__button" dataId={createDataId(dataId, 'saveButton')}>
                                {t('views.company.detail.Save')}
                            </Button>
                        </ContentBlockFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default withSuspense(withTranslation()(EditSupplierModal));
