import React, { ChangeEvent, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from '../../../../storeConfig';
import { eventTrack } from '../../../../common/analytics/gtm';

import { notify } from '../../../../common/utils/notify';
import { uploadInvoiceFile } from '../../../../views/invoice-details/components/invoice-export-management/InvoiceExportManagementActions';
import Modal from '../../../../components/Modal/Modal';
import { InvoiceDTO, FileDTO, AttachmentFilesCountResponseDTO } from '../../../../services/types/ApiTypes';
import { createDataId } from '../../../../common/utils/dataId';
import { ATTACHMENTS_MAX_SIZE, ATTACHMENTS_MAX_SIZE_TOTAL } from '../../../../common/utils/attachmentsHelper';
import { HubType } from '../../../../common/constants/invoiceConstants';

interface Props extends WithTranslation {
    invoice: InvoiceDTO;
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
    setIsLoading: (b: boolean) => void;
    uploadFile: (f: UploadFileParams) => void;
    attachmentsCount?: AttachmentFilesCountResponseDTO;
}

enum RelatedDocType {
    FILE = 'FILE',
    LINK = 'LINK',
}

export type UploadFileParams = { file: FileDTO; isALink: true } | { file: File; isALink?: false };

const initialFileObject: FileDTO = {
    IsImported: false,
    IsOriginal: false,
    IsPdf: false,
    IsNew: true,
    WorkflowDocumentId: null,
    DownloadUrl: null,
    FileUrl: null,
    ForceImport: null,
    PathToFile: null,
    Id: null,
    FileName: null,
    InvoiceId: null,
    Base64Content: null,
    HubType: null,
};

const AddFileModal = (props: Props) => {
    const [fileType, setFileType] = useState<RelatedDocType>(RelatedDocType.FILE);
    const [linkName, setLinkName] = useState<string>();
    const [linkUrl, setLinkUrl] = useState<string>();
    const [file, setFile] = useState<File>(null);

    const closeModal = () => {
        props.setIsOpen(false);
        setFileType(RelatedDocType.FILE);
        setLinkName(null);
        setLinkUrl(null);
        setFile(null);
    };

    const onFileTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileType(e.target.value as RelatedDocType);
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        if (props.attachmentsCount) {
            const totalSizeToBe = props.attachmentsCount.TotalSize + file.size;

            if (file.size > ATTACHMENTS_MAX_SIZE || totalSizeToBe > ATTACHMENTS_MAX_SIZE_TOTAL) {
                notify.warning(props.t('component.attachments.fileTooBig'));
                setFile(null);
                e.target.value = '';
            } else {
                setFile(file);
            }
        } else {
            setFile(file);
        }
    };

    const saveNewDocument = () => {
        if (fileType === RelatedDocType.FILE) {
            // saving a file
            if (!file) {
                notify.warning(props.t('component.relatedDocuments.noFileSelected'));
                return;
            }
            props.uploadFile({ file });
            props.setIsOpen(false);
            setFile(null);
        } else {
            // saving a link
            if (!linkUrl) {
                notify.warning(props.t('component.relatedDocuments.noUrlSpecified'));
                return;
            }
            if (!linkName) {
                notify.warning(props.t('component.relatedDocuments.noNameSpecified'));
                return;
            }
            const newFile: FileDTO = {
                ...initialFileObject,
                FileName: linkName,
                FileUrl: linkUrl,
                InvoiceId: props.invoice.Id,
                HubType: HubType.Emr, // set HubType = Emr, as like it was done manually
            };
            props.uploadFile({ file: newFile, isALink: true });
            props.setIsOpen(false);
            props.setIsLoading(true);
        }

        eventTrack({
            event: 'related_documents',
            label: 'Add new document',
        });
    };

    return (
        <Modal isOpen={props.isOpen} onClose={closeModal} dataId={createDataId('invoice-related-documents', 'add-doc-modal')}>
            <h2 className="modal__header add-related-doc__header">{props.t('component.relatedDocuments.newDocument')}</h2>
            <div className="modal__body ">
                <div className="modal__body-container">
                    <p className="add-related-doc__file">
                        <span className="radio-btn">
                            <input type="radio" checked={fileType === RelatedDocType.FILE} onChange={(e) => onFileTypeChange(e)} name="file" id="file" value={RelatedDocType.FILE} />
                            <label tabIndex={0} className="radio-btn__label" htmlFor="file">
                                {props.t('views.invoice.partials.invoiceFiles.Add_file')}
                            </label>
                        </span>
                        <span className="radio-btn">
                            <input type="radio" checked={fileType === RelatedDocType.LINK} onChange={(e) => onFileTypeChange(e)} name="file" id="link" value={RelatedDocType.LINK} />
                            <label tabIndex={0} className="radio-btn__label" htmlFor="link">
                                {props.t('views.invoice.partials.invoiceFiles.Add_FileUrl')}
                            </label>
                        </span>
                    </p>
                    <div className="add-related-doc__action" data-flow-file-added="fileImageAdded($file, $event)" data-flow-init="" data-flow-drop="">
                        {fileType === RelatedDocType.FILE && !file && (
                            <p>
                                <label data-flow-btn="" className="btn btn--secondary btn--wide btn--md">
                                    {props.t('component.relatedDocuments.selectFile')}
                                    <input type="file" onChange={onFileChange} style={{ visibility: 'hidden', position: 'absolute' }} />
                                </label>
                            </p>
                        )}
                        {fileType === RelatedDocType.FILE && file && (
                            <p>
                                <span className="add-related-doc__label">{props.t('component.relatedDocuments.selectedFile')}</span>
                                {file.name}
                            </p>
                        )}

                        {fileType === RelatedDocType.LINK && (
                            <>
                                <p>
                                    <label htmlFor="linkName" className="add-related-doc__label">
                                        {props.t('component.relatedDocuments.documentName')}
                                    </label>
                                    <input type="text" id="linkName" className="input" autoComplete="off" onChange={(e) => setLinkName(e.target.value)} />
                                </p>
                                <p>
                                    <label htmlFor="linkInput" className="add-related-doc__label">
                                        {props.t('component.relatedDocuments.documentUrl')}
                                    </label>
                                    <input type="text" id="linkInput" className="input" autoComplete="off" onChange={(e) => setLinkUrl(e.target.value)} />
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal__footer">
                <button onClick={closeModal} className="btn btn--alt btn--md">
                    {props.t('component.relatedDocuments.discard')}
                </button>
                <button onClick={saveNewDocument} className="btn btn--primary btn--md">
                    {props.t('component.relatedDocuments.save')}
                </button>
            </div>
        </Modal>
    );
};

export default connect(undefined, { uploadInvoiceFile })(withTranslation()(AddFileModal));
